import { render, staticRenderFns } from "./RequestForm.vue?vue&type=template&id=463ac5cd&"
import script from "./RequestForm.vue?vue&type=script&lang=ts&"
export * from "./RequestForm.vue?vue&type=script&lang=ts&"
import style0 from "./RequestForm.vue?vue&type=style&index=0&id=463ac5cd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports