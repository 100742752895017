




































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class QuantityModal extends Vue {
  private maxQuantity = 1;

  private currentCallback: (q: number) => void = () => {};

  @Prop() private ok!: string;

  public quantity = 0;

  public title = '';

  public instructions = '';

  public active = false;

  public incQuantity(): void {
    if (this.quantity >= this.maxQuantity) return;
    this.quantity += 1;
  }

  public decQuantity(): void {
    if (this.quantity <= 0) return;
    this.quantity -= 1;
  }

  public completeModal(): void {
    this.currentCallback(this.quantity);
    this.hide();
  }

  public show(
    maxQuantity: number,
    title: string,
    instructions: string,
    callback: (q: number) => void,
  ): void {
    this.title = title;
    this.instructions = instructions;
    this.currentCallback = callback;
    this.active = true;
    this.quantity = maxQuantity;
    this.maxQuantity = maxQuantity;
  }

  public hide(): void {
    this.active = false;
  }

  public remove(): void {
    this.$destroy();
    // eslint-disable-next-line
    if (!this.$el || !(this.$el as any).parentNode) return;
    // eslint-disable-next-line
    (this.$el as any).parentNode.removeChild(this.$el);
  }
}
