/* tslint:disable */
/* eslint-disable */
/**
 * Tottus AUTOSAC - Customers NC
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sistemas@arquea.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Full Address.
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Address Line 1.
     * @type {string}
     * @memberof Address
     */
    firstLine?: string;
    /**
     * Address Line 2.
     * @type {string}
     * @memberof Address
     */
    secondLine?: string;
    /**
     * Geographic Code.
     * @type {string}
     * @memberof Address
     */
    geocode?: string;
}
/**
 * Additional data to complete forms.
 * @export
 * @interface AutoComplete
 */
export interface AutoComplete {
    /**
     * Customer\'s name and last Name.
     * @type {string}
     * @memberof AutoComplete
     */
    name?: string;
    /**
     * Customer\'s Email.
     * @type {string}
     * @memberof AutoComplete
     */
    email?: string;
    /**
     * Customer\'s phone number.
     * @type {string}
     * @memberof AutoComplete
     */
    phone?: string;
    /**
     * Address Line 1.
     * @type {string}
     * @memberof AutoComplete
     */
    firstLine?: string;
    /**
     * Address Line 2.
     * @type {string}
     * @memberof AutoComplete
     */
    secondLine?: string;
}
/**
 * Customer information.
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * Document Type.
     * @type {string}
     * @memberof Customer
     */
    documentType: string;
    /**
     * Document Number.
     * @type {string}
     * @memberof Customer
     */
    documentNumber: string;
    /**
     * Name and Last Name.
     * @type {string}
     * @memberof Customer
     */
    name: string;
    /**
     * Customer\'s Email.
     * @type {string}
     * @memberof Customer
     */
    email?: string;
    /**
     * Customer\'s phone number.
     * @type {string}
     * @memberof Customer
     */
    phone?: string;
    /**
     * 
     * @type {Address}
     * @memberof Customer
     */
    addressDetails?: Address;
}
/**
 * 
 * @export
 * @interface GenericResponse
 */
export interface GenericResponse {
    /**
     * Human Readable Message.
     * @type {string}
     * @memberof GenericResponse
     */
    message: string;
    /**
     * Details about the issue.
     * @type {Array<GenericResponseErrors>}
     * @memberof GenericResponse
     */
    errors?: Array<GenericResponseErrors>;
}
/**
 * 
 * @export
 * @interface GenericResponseErrors
 */
export interface GenericResponseErrors {
    /**
     * 
     * @type {string}
     * @memberof GenericResponseErrors
     */
    keyword?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericResponseErrors
     */
    dataPath?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericResponseErrors
     */
    schemaPath?: string;
    /**
     * 
     * @type {GenericResponseParams}
     * @memberof GenericResponseErrors
     */
    params?: GenericResponseParams;
    /**
     * 
     * @type {string}
     * @memberof GenericResponseErrors
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface GenericResponseParams
 */
export interface GenericResponseParams {
    /**
     * 
     * @type {string}
     * @memberof GenericResponseParams
     */
    missingProperty?: string;
}
/**
 * Represents a line in a Transaction or Pre Credit Note.
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * SKU/EAN/PLU Code.
     * @type {string}
     * @memberof Item
     */
    id: string;
    /**
     * Name or description of product.
     * @type {string}
     * @memberof Item
     */
    description: string;
    /**
     * Unit of measure.
     * @type {string}
     * @memberof Item
     */
    unit: string;
    /**
     * Quantity of product.
     * @type {number}
     * @memberof Item
     */
    quantity: number;
    /**
     * Quantity avaiable to generate a Credit Note.
     * @type {number}
     * @memberof Item
     */
    availableQuantity?: number;
    /**
     * Total amount of product.
     * @type {number}
     * @memberof Item
     */
    amount: number;
    /**
     * Available amount to generate a Credit Note.
     * @type {number}
     * @memberof Item
     */
    availableAmount?: number;
}
/**
 * List of pre credit notes.
 * @export
 * @interface ListPreCreditNotes
 */
export interface ListPreCreditNotes {
    /**
     * 
     * @type {Array<PreCreditNote>}
     * @memberof ListPreCreditNotes
     */
    list: Array<PreCreditNote>;
    /**
     * 
     * @type {AutoComplete}
     * @memberof ListPreCreditNotes
     */
    autoComplete: AutoComplete;
}
/**
 * 
 * @export
 * @interface Parameter
 */
export interface Parameter {
    /**
     * Parameter Key.
     * @type {string}
     * @memberof Parameter
     */
    key: string;
    /**
     * Parameter aditional description.
     * @type {string}
     * @memberof Parameter
     */
    description?: string;
    /**
     * Parameter Value.
     * @type {string}
     * @memberof Parameter
     */
    value: string;
}
/**
 * Pre Credit Note Details.
 * @export
 * @interface PreCreditNote
 */
export interface PreCreditNote {
    /**
     * Unique Identifier.
     * @type {string}
     * @memberof PreCreditNote
     */
    id: string;
    /**
     * Short Code.
     * @type {string}
     * @memberof PreCreditNote
     */
    shortCode: string;
    /**
     * PNG Image, serialized in BASE64.
     * @type {string}
     * @memberof PreCreditNote
     */
    barcode: string;
    /**
     * Reference to the original transaction.
     * @type {string}
     * @memberof PreCreditNote
     */
    trxReference: string;
    /**
     * Credit Note motive or type. Only valid types allowed.
     * @type {string}
     * @memberof PreCreditNote
     */
    creditNoteType: string;
    /**
     * Optional message to put in a potential Credit Note.
     * @type {string}
     * @memberof PreCreditNote
     */
    message?: string;
    /**
     * Total amount to refund or exchange.
     * @type {number}
     * @memberof PreCreditNote
     */
    total?: number;
    /**
     * Pre Credit Note create date (UTC).
     * @type {string}
     * @memberof PreCreditNote
     */
    created: string;
    /**
     * Pre Credit Note expiration date (UTC).
     * @type {string}
     * @memberof PreCreditNote
     */
    expires: string;
    /**
     * Image URL.
     * @type {string}
     * @memberof PreCreditNote
     */
    imgUrl?: string;
    /**
     * PDF URL.
     * @type {string}
     * @memberof PreCreditNote
     */
    pdfUrl?: string;
    /**
     * Current Pre Credit Note Status.
     * @type {string}
     * @memberof PreCreditNote
     */
    status: string;
}
/**
 * We assume that the request has also a reference to a transaction.
 * @export
 * @interface PreCreditNoteRequest
 */
export interface PreCreditNoteRequest {
    /**
     * Credit Note motive or type.
     * @type {string}
     * @memberof PreCreditNoteRequest
     */
    creditNoteType: string;
    /**
     * Optional message to put in a potential Credit Note.
     * @type {string}
     * @memberof PreCreditNoteRequest
     */
    message?: string;
    /**
     * 
     * @type {Customer}
     * @memberof PreCreditNoteRequest
     */
    customer: Customer;
    /**
     * List of items that will form the Credit Note.
     * @type {Array<Item>}
     * @memberof PreCreditNoteRequest
     */
    items?: Array<Item>;
    /**
     * Total amount to refund or exchange.
     * @type {number}
     * @memberof PreCreditNoteRequest
     */
    total?: number;
    /**
     * Indicates whether the customer will receive an email with the terms and conditions.
     * @type {boolean}
     * @memberof PreCreditNoteRequest
     */
    sendTerms?: boolean;
}
/**
 * Represents a sale in one of stores.
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * Country where this transaction takes place.
     * @type {string}
     * @memberof Transaction
     */
    country: string;
    /**
     * Store ID.
     * @type {number}
     * @memberof Transaction
     */
    store: number;
    /**
     * POS Number.
     * @type {number}
     * @memberof Transaction
     */
    pos: number;
    /**
     * Sale Date.
     * @type {string}
     * @memberof Transaction
     */
    trxDate: string;
    /**
     * Transaction Number.
     * @type {number}
     * @memberof Transaction
     */
    trx: number;
    /**
     * Payment methods used in this transaction by the customer.
     * @type {Array<string>}
     * @memberof Transaction
     */
    paymentMethods: Array<string>;
    /**
     * List of items in the transaction.
     * @type {Array<Item>}
     * @memberof Transaction
     */
    items?: Array<Item>;
    /**
     * Currency used in the transaction.
     * @type {string}
     * @memberof Transaction
     */
    currency: string;
    /**
     * Total without taxes.
     * @type {number}
     * @memberof Transaction
     */
    subtotal: number;
    /**
     * Taxes amount.
     * @type {number}
     * @memberof Transaction
     */
    taxes: number;
    /**
     * Total, including taxes.
     * @type {number}
     * @memberof Transaction
     */
    total: number;
}

/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the API parameters available. This parameters can be used to form additional client-side validations or to adjusts user restrictions. 
         * @summary Returns a list of API parameters
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameters: async (xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCountry' is not null or undefined
            assertParamExists('getParameters', 'xCountry', xCountry)
            // verify required parameter 'xCommerce' is not null or undefined
            assertParamExists('getParameters', 'xCommerce', xCommerce)
            const localVarPath = `/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCountry !== undefined && xCountry !== null) {
                localVarHeaderParameter['X-country'] = String(xCountry);
            }

            if (xCommerce !== undefined && xCommerce !== null) {
                localVarHeaderParameter['X-commerce'] = String(xCommerce);
            }

            if (xTxRef !== undefined && xTxRef !== null) {
                localVarHeaderParameter['X-txRef'] = String(xTxRef);
            }

            if (xCmRef !== undefined && xCmRef !== null) {
                localVarHeaderParameter['X-cmRef'] = String(xCmRef);
            }

            if (xRhsRef !== undefined && xRhsRef !== null) {
                localVarHeaderParameter['X-rhsRef'] = String(xRhsRef);
            }

            if (xUsrTx !== undefined && xUsrTx !== null) {
                localVarHeaderParameter['X-usrTx'] = String(xUsrTx);
            }

            if (xChRef !== undefined && xChRef !== null) {
                localVarHeaderParameter['X-chRef'] = String(xChRef);
            }

            if (xPrRef !== undefined && xPrRef !== null) {
                localVarHeaderParameter['X-prRef'] = String(xPrRef);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs a health check, checking integrity and dependencies.
         * @summary Health check status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks if an HTTP connection can be established with this API.
         * @summary Heartbeat request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the API parameters available. This parameters can be used to form additional client-side validations or to adjusts user restrictions. 
         * @summary Returns a list of API parameters
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameters(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parameter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameters(xCountry, xCommerce, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs a health check, checking integrity and dependencies.
         * @summary Health check status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks if an HTTP connection can be established with this API.
         * @summary Heartbeat request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async heartbeat(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.heartbeat(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * Returns a list of all the API parameters available. This parameters can be used to form additional client-side validations or to adjusts user restrictions. 
         * @summary Returns a list of API parameters
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameters(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): AxiosPromise<Array<Parameter>> {
            return localVarFp.getParameters(xCountry, xCommerce, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(axios, basePath));
        },
        /**
         * Performs a health check, checking integrity and dependencies.
         * @summary Health check status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheck(options?: any): AxiosPromise<GenericResponse> {
            return localVarFp.healthcheck(options).then((request) => request(axios, basePath));
        },
        /**
         * Checks if an HTTP connection can be established with this API.
         * @summary Heartbeat request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        heartbeat(options?: any): AxiosPromise<GenericResponse> {
            return localVarFp.heartbeat(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * Returns a list of all the API parameters available. This parameters can be used to form additional client-side validations or to adjusts user restrictions. 
     * @summary Returns a list of API parameters
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
     * @param {string} [xTxRef] Transaction Reference (e2e).
     * @param {string} [xCmRef] Consumer Reference (e2e).
     * @param {string} [xRhsRef] Remote Host Reference (e2e).
     * @param {string} [xUsrTx] User Transaction (e2e).
     * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
     * @param {string} [xPrRef] Product Identificator Reference (e2e).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getParameters(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any) {
        return GeneralApiFp(this.configuration).getParameters(xCountry, xCommerce, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Performs a health check, checking integrity and dependencies.
     * @summary Health check status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public healthcheck(options?: any) {
        return GeneralApiFp(this.configuration).healthcheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks if an HTTP connection can be established with this API.
     * @summary Heartbeat request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public heartbeat(options?: any) {
        return GeneralApiFp(this.configuration).heartbeat(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PreCreditNotesApi - axios parameter creator
 * @export
 */
export const PreCreditNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Tries to cancel a Pre Credit Note. This is a one way operation, there is no way to uncancel after that. 
         * @summary Tries to cancel a pre credit note
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xOwner Customers document number.
         * @param {string} id Pre Credit Note ID.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPreCreditNote: async (xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, id: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCountry' is not null or undefined
            assertParamExists('cancelPreCreditNote', 'xCountry', xCountry)
            // verify required parameter 'xCommerce' is not null or undefined
            assertParamExists('cancelPreCreditNote', 'xCommerce', xCommerce)
            // verify required parameter 'xOwner' is not null or undefined
            assertParamExists('cancelPreCreditNote', 'xOwner', xOwner)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelPreCreditNote', 'id', id)
            const localVarPath = `/pre-credit-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCountry !== undefined && xCountry !== null) {
                localVarHeaderParameter['X-country'] = String(xCountry);
            }

            if (xCommerce !== undefined && xCommerce !== null) {
                localVarHeaderParameter['X-commerce'] = String(xCommerce);
            }

            if (xTxRef !== undefined && xTxRef !== null) {
                localVarHeaderParameter['X-txRef'] = String(xTxRef);
            }

            if (xCmRef !== undefined && xCmRef !== null) {
                localVarHeaderParameter['X-cmRef'] = String(xCmRef);
            }

            if (xRhsRef !== undefined && xRhsRef !== null) {
                localVarHeaderParameter['X-rhsRef'] = String(xRhsRef);
            }

            if (xUsrTx !== undefined && xUsrTx !== null) {
                localVarHeaderParameter['X-usrTx'] = String(xUsrTx);
            }

            if (xChRef !== undefined && xChRef !== null) {
                localVarHeaderParameter['X-chRef'] = String(xChRef);
            }

            if (xPrRef !== undefined && xPrRef !== null) {
                localVarHeaderParameter['X-prRef'] = String(xPrRef);
            }

            if (xOwner !== undefined && xOwner !== null) {
                localVarHeaderParameter['X-owner'] = String(xOwner);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Tries to create a new request for a credit note (pre credit note) referencing an existing transaction. The API applies certain limits to the creation of this records.   If there are errors in validating the transaction or if the transaction doesn\'t exist yet you can set the parameter \'force\' to TRUE, this will try to create a pre credit note without validating the transaction that it refers. 
         * @summary Creates a new pre credit note
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xCaptcha Captcha user input.
         * @param {string} xOwner Customers document number.
         * @param {number} store Store ID (where the transaction occurred).
         * @param {number} pos Point Of Sale Number (where the transaction occurred).
         * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
         * @param {number} trx Transaction number.
         * @param {PreCreditNoteRequest} preCreditNoteRequest Request data.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreCreditNote: async (xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, xOwner: string, store: number, pos: number, trxDate: string, trx: number, preCreditNoteRequest: PreCreditNoteRequest, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCountry' is not null or undefined
            assertParamExists('createPreCreditNote', 'xCountry', xCountry)
            // verify required parameter 'xCommerce' is not null or undefined
            assertParamExists('createPreCreditNote', 'xCommerce', xCommerce)
            // verify required parameter 'xCaptcha' is not null or undefined
            assertParamExists('createPreCreditNote', 'xCaptcha', xCaptcha)
            // verify required parameter 'xOwner' is not null or undefined
            assertParamExists('createPreCreditNote', 'xOwner', xOwner)
            // verify required parameter 'store' is not null or undefined
            assertParamExists('createPreCreditNote', 'store', store)
            // verify required parameter 'pos' is not null or undefined
            assertParamExists('createPreCreditNote', 'pos', pos)
            // verify required parameter 'trxDate' is not null or undefined
            assertParamExists('createPreCreditNote', 'trxDate', trxDate)
            // verify required parameter 'trx' is not null or undefined
            assertParamExists('createPreCreditNote', 'trx', trx)
            // verify required parameter 'preCreditNoteRequest' is not null or undefined
            assertParamExists('createPreCreditNote', 'preCreditNoteRequest', preCreditNoteRequest)
            const localVarPath = `/stores/{store}/pos/{pos}/date/{trxDate}/transactions/{trx}/pre-credit-notes`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)))
                .replace(`{${"pos"}}`, encodeURIComponent(String(pos)))
                .replace(`{${"trxDate"}}`, encodeURIComponent(String(trxDate)))
                .replace(`{${"trx"}}`, encodeURIComponent(String(trx)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCountry !== undefined && xCountry !== null) {
                localVarHeaderParameter['X-country'] = String(xCountry);
            }

            if (xCommerce !== undefined && xCommerce !== null) {
                localVarHeaderParameter['X-commerce'] = String(xCommerce);
            }

            if (xTxRef !== undefined && xTxRef !== null) {
                localVarHeaderParameter['X-txRef'] = String(xTxRef);
            }

            if (xCmRef !== undefined && xCmRef !== null) {
                localVarHeaderParameter['X-cmRef'] = String(xCmRef);
            }

            if (xRhsRef !== undefined && xRhsRef !== null) {
                localVarHeaderParameter['X-rhsRef'] = String(xRhsRef);
            }

            if (xUsrTx !== undefined && xUsrTx !== null) {
                localVarHeaderParameter['X-usrTx'] = String(xUsrTx);
            }

            if (xChRef !== undefined && xChRef !== null) {
                localVarHeaderParameter['X-chRef'] = String(xChRef);
            }

            if (xPrRef !== undefined && xPrRef !== null) {
                localVarHeaderParameter['X-prRef'] = String(xPrRef);
            }

            if (xCaptcha !== undefined && xCaptcha !== null) {
                localVarHeaderParameter['X-captcha'] = String(xCaptcha);
            }

            if (xOwner !== undefined && xOwner !== null) {
                localVarHeaderParameter['X-owner'] = String(xOwner);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preCreditNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This methods returns a image with data from a pre credit note. 
         * @summary Retrieves the image from a pre credit note
         * @param {string} id Pre Credit Note ID.
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
         * @param {string} own Customers document number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreCreditNoteImg: async (id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPreCreditNoteImg', 'id', id)
            // verify required parameter 'ctry' is not null or undefined
            assertParamExists('getPreCreditNoteImg', 'ctry', ctry)
            // verify required parameter 'comm' is not null or undefined
            assertParamExists('getPreCreditNoteImg', 'comm', comm)
            // verify required parameter 'own' is not null or undefined
            assertParamExists('getPreCreditNoteImg', 'own', own)
            const localVarPath = `/pre-credit-notes/{id}/img`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ctry !== undefined) {
                localVarQueryParameter['ctry'] = ctry;
            }

            if (comm !== undefined) {
                localVarQueryParameter['comm'] = comm;
            }

            if (own !== undefined) {
                localVarQueryParameter['own'] = own;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This methods returns a PDF document with data from a pre credit note. 
         * @summary Retrieves the PDF from a pre credit note
         * @param {string} id Pre Credit Note ID.
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
         * @param {string} own Customers document number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreCreditNotePdf: async (id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPreCreditNotePdf', 'id', id)
            // verify required parameter 'ctry' is not null or undefined
            assertParamExists('getPreCreditNotePdf', 'ctry', ctry)
            // verify required parameter 'comm' is not null or undefined
            assertParamExists('getPreCreditNotePdf', 'comm', comm)
            // verify required parameter 'own' is not null or undefined
            assertParamExists('getPreCreditNotePdf', 'own', own)
            const localVarPath = `/pre-credit-notes/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ctry !== undefined) {
                localVarQueryParameter['ctry'] = ctry;
            }

            if (comm !== undefined) {
                localVarQueryParameter['comm'] = comm;
            }

            if (own !== undefined) {
                localVarQueryParameter['own'] = own;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Queries for a transaction in the PMM Database. Should a transaction exists it returns it\'s basic information and list of product items. 
         * @summary Queries for a transaction and returns it\'s details
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xCaptcha Captcha user input.
         * @param {number} store Store ID (where the transaction occurred).
         * @param {number} pos Point Of Sale Number (where the transaction occurred).
         * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
         * @param {number} trx Transaction number.
         * @param {number} trxAmount Transaction amount total.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, store: number, pos: number, trxDate: string, trx: number, trxAmount: number, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCountry' is not null or undefined
            assertParamExists('getTransaction', 'xCountry', xCountry)
            // verify required parameter 'xCommerce' is not null or undefined
            assertParamExists('getTransaction', 'xCommerce', xCommerce)
            // verify required parameter 'xCaptcha' is not null or undefined
            assertParamExists('getTransaction', 'xCaptcha', xCaptcha)
            // verify required parameter 'store' is not null or undefined
            assertParamExists('getTransaction', 'store', store)
            // verify required parameter 'pos' is not null or undefined
            assertParamExists('getTransaction', 'pos', pos)
            // verify required parameter 'trxDate' is not null or undefined
            assertParamExists('getTransaction', 'trxDate', trxDate)
            // verify required parameter 'trx' is not null or undefined
            assertParamExists('getTransaction', 'trx', trx)
            // verify required parameter 'trxAmount' is not null or undefined
            assertParamExists('getTransaction', 'trxAmount', trxAmount)
            const localVarPath = `/stores/{store}/pos/{pos}/date/{trxDate}/transactions/{trx}`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)))
                .replace(`{${"pos"}}`, encodeURIComponent(String(pos)))
                .replace(`{${"trxDate"}}`, encodeURIComponent(String(trxDate)))
                .replace(`{${"trx"}}`, encodeURIComponent(String(trx)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (trxAmount !== undefined) {
                localVarQueryParameter['trxAmount'] = trxAmount;
            }

            if (xCountry !== undefined && xCountry !== null) {
                localVarHeaderParameter['X-country'] = String(xCountry);
            }

            if (xCommerce !== undefined && xCommerce !== null) {
                localVarHeaderParameter['X-commerce'] = String(xCommerce);
            }

            if (xTxRef !== undefined && xTxRef !== null) {
                localVarHeaderParameter['X-txRef'] = String(xTxRef);
            }

            if (xCmRef !== undefined && xCmRef !== null) {
                localVarHeaderParameter['X-cmRef'] = String(xCmRef);
            }

            if (xRhsRef !== undefined && xRhsRef !== null) {
                localVarHeaderParameter['X-rhsRef'] = String(xRhsRef);
            }

            if (xUsrTx !== undefined && xUsrTx !== null) {
                localVarHeaderParameter['X-usrTx'] = String(xUsrTx);
            }

            if (xChRef !== undefined && xChRef !== null) {
                localVarHeaderParameter['X-chRef'] = String(xChRef);
            }

            if (xPrRef !== undefined && xPrRef !== null) {
                localVarHeaderParameter['X-prRef'] = String(xPrRef);
            }

            if (xCaptcha !== undefined && xCaptcha !== null) {
                localVarHeaderParameter['X-captcha'] = String(xCaptcha);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns the status of the last \'x\' records generated by a user identified by his document number. The quantity of pre credit notes that the API retrieves is an internal parameter of the system. 
         * @summary Retrieves the status of the last few pre credit notes created
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xOwner Customers document number.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPreCreditNotes: async (xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCountry' is not null or undefined
            assertParamExists('listPreCreditNotes', 'xCountry', xCountry)
            // verify required parameter 'xCommerce' is not null or undefined
            assertParamExists('listPreCreditNotes', 'xCommerce', xCommerce)
            // verify required parameter 'xOwner' is not null or undefined
            assertParamExists('listPreCreditNotes', 'xOwner', xOwner)
            const localVarPath = `/pre-credit-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCountry !== undefined && xCountry !== null) {
                localVarHeaderParameter['X-country'] = String(xCountry);
            }

            if (xCommerce !== undefined && xCommerce !== null) {
                localVarHeaderParameter['X-commerce'] = String(xCommerce);
            }

            if (xTxRef !== undefined && xTxRef !== null) {
                localVarHeaderParameter['X-txRef'] = String(xTxRef);
            }

            if (xCmRef !== undefined && xCmRef !== null) {
                localVarHeaderParameter['X-cmRef'] = String(xCmRef);
            }

            if (xRhsRef !== undefined && xRhsRef !== null) {
                localVarHeaderParameter['X-rhsRef'] = String(xRhsRef);
            }

            if (xUsrTx !== undefined && xUsrTx !== null) {
                localVarHeaderParameter['X-usrTx'] = String(xUsrTx);
            }

            if (xChRef !== undefined && xChRef !== null) {
                localVarHeaderParameter['X-chRef'] = String(xChRef);
            }

            if (xPrRef !== undefined && xPrRef !== null) {
                localVarHeaderParameter['X-prRef'] = String(xPrRef);
            }

            if (xOwner !== undefined && xOwner !== null) {
                localVarHeaderParameter['X-owner'] = String(xOwner);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreCreditNotesApi - functional programming interface
 * @export
 */
export const PreCreditNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreCreditNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * Tries to cancel a Pre Credit Note. This is a one way operation, there is no way to uncancel after that. 
         * @summary Tries to cancel a pre credit note
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xOwner Customers document number.
         * @param {string} id Pre Credit Note ID.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPreCreditNote(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, id: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPreCreditNote(xCountry, xCommerce, xOwner, id, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Tries to create a new request for a credit note (pre credit note) referencing an existing transaction. The API applies certain limits to the creation of this records.   If there are errors in validating the transaction or if the transaction doesn\'t exist yet you can set the parameter \'force\' to TRUE, this will try to create a pre credit note without validating the transaction that it refers. 
         * @summary Creates a new pre credit note
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xCaptcha Captcha user input.
         * @param {string} xOwner Customers document number.
         * @param {number} store Store ID (where the transaction occurred).
         * @param {number} pos Point Of Sale Number (where the transaction occurred).
         * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
         * @param {number} trx Transaction number.
         * @param {PreCreditNoteRequest} preCreditNoteRequest Request data.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreCreditNote(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, xOwner: string, store: number, pos: number, trxDate: string, trx: number, preCreditNoteRequest: PreCreditNoteRequest, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreCreditNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreCreditNote(xCountry, xCommerce, xCaptcha, xOwner, store, pos, trxDate, trx, preCreditNoteRequest, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This methods returns a image with data from a pre credit note. 
         * @summary Retrieves the image from a pre credit note
         * @param {string} id Pre Credit Note ID.
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
         * @param {string} own Customers document number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreCreditNoteImg(id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreCreditNoteImg(id, ctry, comm, own, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This methods returns a PDF document with data from a pre credit note. 
         * @summary Retrieves the PDF from a pre credit note
         * @param {string} id Pre Credit Note ID.
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
         * @param {string} own Customers document number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreCreditNotePdf(id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreCreditNotePdf(id, ctry, comm, own, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Queries for a transaction in the PMM Database. Should a transaction exists it returns it\'s basic information and list of product items. 
         * @summary Queries for a transaction and returns it\'s details
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xCaptcha Captcha user input.
         * @param {number} store Store ID (where the transaction occurred).
         * @param {number} pos Point Of Sale Number (where the transaction occurred).
         * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
         * @param {number} trx Transaction number.
         * @param {number} trxAmount Transaction amount total.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, store: number, pos: number, trxDate: string, trx: number, trxAmount: number, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(xCountry, xCommerce, xCaptcha, store, pos, trxDate, trx, trxAmount, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns the status of the last \'x\' records generated by a user identified by his document number. The quantity of pre credit notes that the API retrieves is an internal parameter of the system. 
         * @summary Retrieves the status of the last few pre credit notes created
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xOwner Customers document number.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPreCreditNotes(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPreCreditNotes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPreCreditNotes(xCountry, xCommerce, xOwner, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreCreditNotesApi - factory interface
 * @export
 */
export const PreCreditNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreCreditNotesApiFp(configuration)
    return {
        /**
         * Tries to cancel a Pre Credit Note. This is a one way operation, there is no way to uncancel after that. 
         * @summary Tries to cancel a pre credit note
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xOwner Customers document number.
         * @param {string} id Pre Credit Note ID.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPreCreditNote(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, id: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): AxiosPromise<GenericResponse> {
            return localVarFp.cancelPreCreditNote(xCountry, xCommerce, xOwner, id, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(axios, basePath));
        },
        /**
         * Tries to create a new request for a credit note (pre credit note) referencing an existing transaction. The API applies certain limits to the creation of this records.   If there are errors in validating the transaction or if the transaction doesn\'t exist yet you can set the parameter \'force\' to TRUE, this will try to create a pre credit note without validating the transaction that it refers. 
         * @summary Creates a new pre credit note
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xCaptcha Captcha user input.
         * @param {string} xOwner Customers document number.
         * @param {number} store Store ID (where the transaction occurred).
         * @param {number} pos Point Of Sale Number (where the transaction occurred).
         * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
         * @param {number} trx Transaction number.
         * @param {PreCreditNoteRequest} preCreditNoteRequest Request data.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreCreditNote(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, xOwner: string, store: number, pos: number, trxDate: string, trx: number, preCreditNoteRequest: PreCreditNoteRequest, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): AxiosPromise<PreCreditNote> {
            return localVarFp.createPreCreditNote(xCountry, xCommerce, xCaptcha, xOwner, store, pos, trxDate, trx, preCreditNoteRequest, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(axios, basePath));
        },
        /**
         * This methods returns a image with data from a pre credit note. 
         * @summary Retrieves the image from a pre credit note
         * @param {string} id Pre Credit Note ID.
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
         * @param {string} own Customers document number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreCreditNoteImg(id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPreCreditNoteImg(id, ctry, comm, own, options).then((request) => request(axios, basePath));
        },
        /**
         * This methods returns a PDF document with data from a pre credit note. 
         * @summary Retrieves the PDF from a pre credit note
         * @param {string} id Pre Credit Note ID.
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
         * @param {string} own Customers document number.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreCreditNotePdf(id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPreCreditNotePdf(id, ctry, comm, own, options).then((request) => request(axios, basePath));
        },
        /**
         * Queries for a transaction in the PMM Database. Should a transaction exists it returns it\'s basic information and list of product items. 
         * @summary Queries for a transaction and returns it\'s details
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xCaptcha Captcha user input.
         * @param {number} store Store ID (where the transaction occurred).
         * @param {number} pos Point Of Sale Number (where the transaction occurred).
         * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
         * @param {number} trx Transaction number.
         * @param {number} trxAmount Transaction amount total.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, store: number, pos: number, trxDate: string, trx: number, trxAmount: number, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): AxiosPromise<Transaction> {
            return localVarFp.getTransaction(xCountry, xCommerce, xCaptcha, store, pos, trxDate, trx, trxAmount, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns the status of the last \'x\' records generated by a user identified by his document number. The quantity of pre credit notes that the API retrieves is an internal parameter of the system. 
         * @summary Retrieves the status of the last few pre credit notes created
         * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
         * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
         * @param {string} xOwner Customers document number.
         * @param {string} [xTxRef] Transaction Reference (e2e).
         * @param {string} [xCmRef] Consumer Reference (e2e).
         * @param {string} [xRhsRef] Remote Host Reference (e2e).
         * @param {string} [xUsrTx] User Transaction (e2e).
         * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
         * @param {string} [xPrRef] Product Identificator Reference (e2e).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPreCreditNotes(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any): AxiosPromise<ListPreCreditNotes> {
            return localVarFp.listPreCreditNotes(xCountry, xCommerce, xOwner, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreCreditNotesApi - object-oriented interface
 * @export
 * @class PreCreditNotesApi
 * @extends {BaseAPI}
 */
export class PreCreditNotesApi extends BaseAPI {
    /**
     * Tries to cancel a Pre Credit Note. This is a one way operation, there is no way to uncancel after that. 
     * @summary Tries to cancel a pre credit note
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
     * @param {string} xOwner Customers document number.
     * @param {string} id Pre Credit Note ID.
     * @param {string} [xTxRef] Transaction Reference (e2e).
     * @param {string} [xCmRef] Consumer Reference (e2e).
     * @param {string} [xRhsRef] Remote Host Reference (e2e).
     * @param {string} [xUsrTx] User Transaction (e2e).
     * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
     * @param {string} [xPrRef] Product Identificator Reference (e2e).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreCreditNotesApi
     */
    public cancelPreCreditNote(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, id: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any) {
        return PreCreditNotesApiFp(this.configuration).cancelPreCreditNote(xCountry, xCommerce, xOwner, id, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Tries to create a new request for a credit note (pre credit note) referencing an existing transaction. The API applies certain limits to the creation of this records.   If there are errors in validating the transaction or if the transaction doesn\'t exist yet you can set the parameter \'force\' to TRUE, this will try to create a pre credit note without validating the transaction that it refers. 
     * @summary Creates a new pre credit note
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
     * @param {string} xCaptcha Captcha user input.
     * @param {string} xOwner Customers document number.
     * @param {number} store Store ID (where the transaction occurred).
     * @param {number} pos Point Of Sale Number (where the transaction occurred).
     * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
     * @param {number} trx Transaction number.
     * @param {PreCreditNoteRequest} preCreditNoteRequest Request data.
     * @param {string} [xTxRef] Transaction Reference (e2e).
     * @param {string} [xCmRef] Consumer Reference (e2e).
     * @param {string} [xRhsRef] Remote Host Reference (e2e).
     * @param {string} [xUsrTx] User Transaction (e2e).
     * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
     * @param {string} [xPrRef] Product Identificator Reference (e2e).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreCreditNotesApi
     */
    public createPreCreditNote(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, xOwner: string, store: number, pos: number, trxDate: string, trx: number, preCreditNoteRequest: PreCreditNoteRequest, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any) {
        return PreCreditNotesApiFp(this.configuration).createPreCreditNote(xCountry, xCommerce, xCaptcha, xOwner, store, pos, trxDate, trx, preCreditNoteRequest, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This methods returns a image with data from a pre credit note. 
     * @summary Retrieves the image from a pre credit note
     * @param {string} id Pre Credit Note ID.
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
     * @param {string} own Customers document number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreCreditNotesApi
     */
    public getPreCreditNoteImg(id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options?: any) {
        return PreCreditNotesApiFp(this.configuration).getPreCreditNoteImg(id, ctry, comm, own, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This methods returns a PDF document with data from a pre credit note. 
     * @summary Retrieves the PDF from a pre credit note
     * @param {string} id Pre Credit Note ID.
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} ctry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} comm Commerce (e2e).
     * @param {string} own Customers document number.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreCreditNotesApi
     */
    public getPreCreditNotePdf(id: string, ctry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', comm: 'Sodimac' | 'Tottus' | 'Falabella', own: string, options?: any) {
        return PreCreditNotesApiFp(this.configuration).getPreCreditNotePdf(id, ctry, comm, own, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Queries for a transaction in the PMM Database. Should a transaction exists it returns it\'s basic information and list of product items. 
     * @summary Queries for a transaction and returns it\'s details
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
     * @param {string} xCaptcha Captcha user input.
     * @param {number} store Store ID (where the transaction occurred).
     * @param {number} pos Point Of Sale Number (where the transaction occurred).
     * @param {string} trxDate Date of the transaction (in format \&#39;yyyy-MM-dd\&#39;).
     * @param {number} trx Transaction number.
     * @param {number} trxAmount Transaction amount total.
     * @param {string} [xTxRef] Transaction Reference (e2e).
     * @param {string} [xCmRef] Consumer Reference (e2e).
     * @param {string} [xRhsRef] Remote Host Reference (e2e).
     * @param {string} [xUsrTx] User Transaction (e2e).
     * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
     * @param {string} [xPrRef] Product Identificator Reference (e2e).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreCreditNotesApi
     */
    public getTransaction(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xCaptcha: string, store: number, pos: number, trxDate: string, trx: number, trxAmount: number, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any) {
        return PreCreditNotesApiFp(this.configuration).getTransaction(xCountry, xCommerce, xCaptcha, store, pos, trxDate, trx, trxAmount, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns the status of the last \'x\' records generated by a user identified by his document number. The quantity of pre credit notes that the API retrieves is an internal parameter of the system. 
     * @summary Retrieves the status of the last few pre credit notes created
     * @param {'CL' | 'AR' | 'PE' | 'CO' | 'MX'} xCountry Country.
     * @param {'Sodimac' | 'Tottus' | 'Falabella'} xCommerce Commerce (e2e).
     * @param {string} xOwner Customers document number.
     * @param {string} [xTxRef] Transaction Reference (e2e).
     * @param {string} [xCmRef] Consumer Reference (e2e).
     * @param {string} [xRhsRef] Remote Host Reference (e2e).
     * @param {string} [xUsrTx] User Transaction (e2e).
     * @param {'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko'} [xChRef] Channel Reference (e2e).
     * @param {string} [xPrRef] Product Identificator Reference (e2e).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreCreditNotesApi
     */
    public listPreCreditNotes(xCountry: 'CL' | 'AR' | 'PE' | 'CO' | 'MX', xCommerce: 'Sodimac' | 'Tottus' | 'Falabella', xOwner: string, xTxRef?: string, xCmRef?: string, xRhsRef?: string, xUsrTx?: string, xChRef?: 'WEB' | 'Mobile' | 'Bot' | 'POS' | 'Kiosko', xPrRef?: string, options?: any) {
        return PreCreditNotesApiFp(this.configuration).listPreCreditNotes(xCountry, xCommerce, xOwner, xTxRef, xCmRef, xRhsRef, xUsrTx, xChRef, xPrRef, options).then((request) => request(this.axios, this.basePath));
    }
}


