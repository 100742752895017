



















































































































import { mixins } from 'vue-class-component';
import VoucherFormGenerics from '@/mixins/VoucherFormGenerics';

export default class VoucherFormCl extends mixins(VoucherFormGenerics) {}
