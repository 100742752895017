

































import Component, { mixins } from 'vue-class-component';
import DomHelper from './mixins/DomHelper';
import Localizer from './mixins/Localizer';
import PreCreditNoteHandler from './mixins/PreCreditNoteHandler';

@Component
export default class App extends mixins(
  PreCreditNoteHandler,
  DomHelper,
  Localizer,
) {
  public contentPadding = 0;

  public mounted(): void {
    this.observePaths();
    document.title = this.$t('title').toString();
    this.onResize = this.centerContent;
    this.centerContent();
  }

  private centerContent(): void {
    const { innerWidth, innerHeight } = window;
    if (innerWidth <= 768) {
      this.contentPadding = 0;
      return;
    }

    const brc = this.$refs.brc as HTMLElement;
    if (!brc) return;
    const contentHeight = brc.clientHeight;
    const wrapperHeight = innerHeight - 200;

    if (contentHeight < wrapperHeight) {
      const newh = (wrapperHeight - contentHeight) / 2;
      const diff = Math.abs(this.contentPadding - newh);
      if (diff < 10) return;
      this.contentPadding = newh;
    } else {
      this.contentPadding = 0;
    }
  }
}
