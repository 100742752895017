import { MessageContext } from 'vue-i18n';
import { validate, format } from 'rut.js';
import moment from 'moment';

const OK = 'ok';
const FAIL = 'fail';

const extractInput = (ctx: MessageContext): { valid: boolean, str: string } => {
  const str = ctx.values.data as string;
  if (str === undefined) return { valid: false, str: '' };
  return { valid: true, str };
};

const data = {
  getHoursLeft: (ctx: MessageContext): string => {
    const date = ctx.values.data as string;
    const end = moment(date);
    const totalMinutes = end.diff(moment.now(), 'minutes');
    const hrs = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;
    let result = '';
    if (hrs <= 0 && mins > 0) {
      const extra = mins !== 1 ? ' minutos' : ' minuto';
      result = 'Solicitud vigente por ' + mins.toString() + extra;
    } else if (mins <= 0 && hrs > 0) {
      const extra = hrs !== 1 ? ' horas' : ' hora';
      result = 'Solicitud vigente por ' + hrs.toString() + extra;
    } else if (hrs > 0 && mins > 0) {
      const xMins = mins.toString() + (mins !== 1 ? ' minutos' : ' minuto');
      const xHours = hrs.toString() + (hrs !== 1 ? ' horas' : ' hora');
      result = 'Solicitud vigente por ' + xHours + ' y ' + xMins;
    }

    return result;
  },

  cleanDoc: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return str;
    return format(str);
  },

  validateDoc: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return 'validation.docEmpty';
    if (!str || str.trim() === '') return 'validation.docEmpty';

    const [docType, doc] = str.split(':');
    if (!doc || doc.trim() === '') return 'validation.docEmpty';

    if (docType !== 'RUT') return 'validation.docInvalid';

    const cleaned = doc.replaceAll('.', '').replaceAll('-', '').trim();
    const seemsLikeRut = /^[0-9kK]+$/.test(cleaned);
    if (!seemsLikeRut) return 'validation.rutWrongCharacters';

    return validate(doc) ? OK : 'validation.rutInvalid';
  },

  validateNames: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return FAIL;
    return str.trim().length >= 2 ? OK : FAIL;
  },

  validateAddress: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return FAIL;
    return str.trim().length >= 2 ? OK : FAIL;
  },

  validateCity: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return FAIL;
    return str.trim().length >= 2 ? OK : FAIL;
  },

  validatePhone: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return FAIL;
    if (str.trim() === '') return OK; // optional

    const digitsOnly = /^\d+$/.test(str);
    const correctLength = str.length >= 7;
    return (digitsOnly && correctLength) ? OK : FAIL;
  },

  validateEmail: (ctx: MessageContext): string => {
    const { valid, str } = extractInput(ctx);
    if (!valid) return FAIL;
    if (str.trim() === '') return OK; // optional

    const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str);
    return validEmail ? OK : FAIL;
  },
};

export default data;
