
































































import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import DomHelper from '@/mixins/DomHelper';
import Notifier from '@/mixins/Notifier';
import Spinner from '@/components/elements/Spinner.vue';

const defaultCallback = async (): Promise<void> => {};

@Component({
  components: {
    Spinner,
  },
})
export default class ConfirmModal extends mixins(DomHelper, Notifier) {
  private currentCallback: (accept: boolean) => Promise<void> = defaultCallback;

  public active = false;

  public processing = false;

  public complete = false;

  @Prop() private title!: string;

  @Prop() private message!: string;

  @Prop() private yes!: string;

  @Prop() private no!: string;

  @Prop() private ok!: string;

  @Prop() private completeMessage!: string;

  @Prop() private onError!: (err: Error) => void;

  public completeModal(): void {
    this.hide();
  }

  public show(callback: (confirm: boolean) => Promise<void>): void {
    this.currentCallback = callback;
    this.active = true;
    this.complete = false;
    this.processing = false;
  }

  public hide(): void {
    this.currentCallback = defaultCallback;
    this.active = false;
  }

  public async accept(): Promise<void> {
    this.processing = true;
    try {
      await this.currentCallback(true);
      this.complete = true;
    } catch (err) {
      this.hide();
      this.onError(err);
    } finally {
      this.processing = false;
    }
  }

  public async cancel(): Promise<void> {
    this.processing = true;
    try {
      await this.currentCallback(false);
      this.hide();
    } catch (err) {
      this.hide();
      this.onError(err);
    } finally {
      this.processing = false;
    }
  }

  public remove(): void {
    this.$destroy();
    // eslint-disable-next-line
    if (!this.$el || !(this.$el as any).parentNode) return;
    // eslint-disable-next-line
    (this.$el as any).parentNode.removeChild(this.$el);
  }
}
