





























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import DirectInput from '@/components/elements/DirectInput.vue';
import { CustomerInfo } from '@/core/CustomerInfo';
import { ValidationObject } from './lib/ValidationObject';
import { I18nCls } from '@/i18n/i18nPlugin';

@Component({
  components: {
    DirectInput,
  },
})
export default class RequestForm extends Vue {
  @Prop() private value!: CustomerInfo;

  public acceptPolicy = false;

  public validName: ValidationObject = { valid: undefined, err: '' };

  public validAddress: ValidationObject = { valid: undefined, err: '' };

  public validCity: ValidationObject = { valid: undefined, err: '' };

  public validPhone: ValidationObject = { valid: undefined, err: '' };

  public validEmail: ValidationObject = { valid: undefined, err: '' };

  public get environment(): string {
    return I18nCls.environment;
  }

  public get optionalName(): boolean {
    return this.evaluate('validateNames', '');
  }

  public get optionalAddress(): boolean {
    return this.evaluate('validateAddress', '');
  }

  public get optionalCity(): boolean {
    return this.evaluate('validateCity', '');
  }

  public get optionalPhone(): boolean {
    return this.evaluate('validatePhone', '');
  }

  public get optionalEmail(): boolean {
    return this.evaluate('validateEmail', '');
  }

  public checkStandings(): void {
    const vn =
      this.validName.valid === true ||
      (this.value.name === '' && this.optionalName);
    const va =
      this.validAddress.valid === true ||
      (this.value.address === '' && this.optionalAddress);
    const vc =
      this.validCity.valid === true ||
      (this.value.city === '' && this.optionalCity);
    const vp =
      this.validPhone.valid === true ||
      (this.value.phone === '' && this.optionalPhone);
    const ve =
      this.validEmail.valid === true ||
      (this.value.email === '' && this.optionalEmail);

    const valid = vn && va && vc && vp && ve;
    this.$emit(this.acceptPolicy ? 'policyOk' : 'policyFail');
    this.$emit(valid ? 'valid' : 'invalid');
  }

  public focusSequence(n: number): void {
    // eslint-disable-next-line
    const input = this.$refs[`f${n}`] as DirectInput;
    if (!input || typeof input.manualFocus !== 'function') return;
    input.manualFocus();
  }

  public blurSequence(n: number): void {
    // eslint-disable-next-line
    const input = this.$refs[`f${n}`] as DirectInput;
    if (!input || typeof input.manualFocus !== 'function') return;
    input.manualBlur();
  }

  private nextInput(index: number): void {
    this.$emit('input', this.value);
    if (index >= 5) {
      this.blurSequence(index);
      return;
    }

    this.focusSequence(index + 1);
  }

  private validate(
    next: number,
    fn: () => boolean,
    err: TranslateResult | string,
  ): ValidationObject {
    if (!this.value) return { valid: false, err: '' };
    let validObj = { valid: true, err: '' };
    if (!fn()) validObj = { valid: false, err: err.toString() };
    if (next > 0) this.nextInput(next);
    return validObj;
  }

  private evaluate(fname: string, value: string): boolean {
    const obj = this.$tfn.getBoolean(this, fname, value);
    return obj.result;
  }

  private validateNames(goNext = true): boolean {
    const next = goNext ? 1 : -1;
    const cb = () => this.evaluate('validateNames', this.value.name);
    const v = this.validate(next, cb, this.$t('requestForm.nameError'));
    this.validName = v;
    this.checkStandings();
    return v.valid || false;
  }

  private validateAddress(goNext = true): boolean {
    const next = goNext ? 2 : -1;
    const cb = () => this.evaluate('validateAddress', this.value.address);
    const v = this.validate(next, cb, this.$t('requestForm.addressError'));
    this.validAddress = v;
    this.checkStandings();
    return v.valid || false;
  }

  private validateCity(goNext = true): boolean {
    const next = goNext ? 3 : -1;
    const cb = () => this.evaluate('validateCity', this.value.city);
    const v = this.validate(next, cb, this.$t('requestForm.cityError'));
    this.validCity = v;
    this.checkStandings();
    return v.valid || false;
  }

  private validatePhone(goNext = true): boolean {
    const next = goNext ? 4 : -1;
    const cb = () => this.evaluate('validatePhone', this.value.phone);
    const v = this.validate(next, cb, this.$t('requestForm.phoneError'));
    this.validPhone = v;
    this.checkStandings();
    return v.valid || false;
  }

  private validateEmail(goNext = true): boolean {
    const next = goNext ? 5 : -1;
    const cb = () => this.evaluate('validateEmail', this.value.email);
    const v = this.validate(next, cb, this.$t('requestForm.emailError'));
    this.validEmail = v;
    this.checkStandings();
    return v.valid || false;
  }
}
