export interface CustomerInfo {
  name: string,
  address: string,
  city: string,
  phone: string,
  email: string,
  sendTerms: boolean,
}

export const DefaultCustomerInfo = (): CustomerInfo => ({
  name: '',
  address: '',
  city: '',
  phone: '',
  email: '',
  sendTerms: false,
});
