import { Locales } from './locales';

import peStr from './es-PE.json';
import clStr from './es-CL.json';
import peFn from './es-PE.functions';
import clFn from './es-CL.functions';

export const messages = {
  [Locales.ES_PE]: { fn: peFn, ...peStr },
  [Locales.ES_CL]: { fn: clFn, ...clStr },
};

export const defaultLocale = Locales.ES_PE;
