



























import { Transaction } from 'client-nc-sdk';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import Localizer from '@/mixins/Localizer';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';

@Component
export default class TransactionDetails extends mixins(
  Localizer,
  PreCreditNoteHandler,
) {
  private startTime = 0;

  @Prop() private trx!: Transaction;

  private getPaymentMethods(trx: Transaction): string {
    return trx.paymentMethods.length <= 0 ? '-' : trx.paymentMethods.join(', ');
  }

  public getFullDate(date: string): string {
    return moment(date).format('DD/MM/YYYY hh:mm A');
  }

  public getHoursLeft(date: string, start: number): string {
    this.startTime = start;
    return this.$tfn.getString(this, 'getHoursLeft', date);
  }

  public getStringDate(date: string, addHours = 0): string {
    return moment(date).add(addHours, 'hours').format('DD/MM/YYYY');
  }
}
