















import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DomHelper from '@/mixins/DomHelper';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import Notifier from '@/mixins/Notifier';
import { DefaultProvider } from '@/core/provider';
import Breadcrumb, { BreadcrumbStep } from '@/components/Breadcrumb.vue';
import Spinner from '@/components/elements/Spinner.vue';
import sleep from '@/core/helpers/Sleep';
import RequestType from '@/core/RequestType';
import Localizer from '@/mixins/Localizer';

@Component({
  components: {
    Breadcrumb,
    Spinner,
  },
})
export default class VoucherRequest extends mixins(
  PreCreditNoteHandler,
  DomHelper,
  Notifier,
  Localizer,
) {
  private canceled = false;

  private srv = DefaultProvider;

  public currentStep: BreadcrumbStep = BreadcrumbStep.VoucherLookup;

  private captchaToken = '';

  public get completed(): boolean {
    return this.store.data.transaction.trx.trim() !== '';
  }

  public async processAll(): Promise<void> {
    const captchaOk = await this.processCaptcha();
    if (!captchaOk) return;

    const res = await this.srv.getTransaction(
      this.store.data.voucher,
      this.captchaToken,
      this.store,
    );

    if (!res.isValid && !this.canceled) {
      const msg = this.$tfn.getErrorMsg(this, 'voucherRequest', res.err);
      this.showError(msg, res.err.id, () => this.goBack());
      return;
    }

    await sleep(700);
    if (this.canceled) return;

    const { type } = this.store.data;
    const noItems = (this.store.data.transaction.items || []).length <= 0;
    if (type === RequestType.FULL_REFUND || noItems) {
      this.$router.push(this.localizeRoute('request-full-refund'));
    } else {
      this.$router.push(this.localizeRoute('request-partial-refund'));
    }
  }

  public mounted(): void {
    if (!this.isVoucherFilled()) {
      this.goBack();
      return;
    }

    this.processAll().finally();
  }

  private async processCaptcha(): Promise<boolean> {
    this.store.setProcessing(true);
    try {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      if (!token) {
        throw new Error(this.$t('voucherRequest.invalidCaptcha').toString());
      }

      this.captchaToken = token;
      return true;
    } catch (ex) {
      this.showError(ex, '', () => this.goBack());
      return false;
    } finally {
      this.store.setProcessing(false);
    }
  }

  private goBack(): void {
    this.canceled = true;
    this.$router.push(this.localizeRoute('voucher-lookup'));
  }
}
