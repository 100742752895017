import { Transaction, PreCreditNote as PCN, Item } from 'client-nc-sdk';
import {
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { CustomerInfo, DefaultCustomerInfo } from '@/core/CustomerInfo';
import { ItemWithQuantity } from '@/core/ItemWithQuantity';
import { ErrorType } from '@/core/provider/lib/ErrorType';
import RequestType from '@/core/RequestType';
import { DefaultVoucherInput, VoucherInput } from '@/core/VoucherInput';
import { Locales } from '@/i18n/locales';
import { defaultLocale } from '@/i18n';
import { ResultError } from '@/core/provider/lib/ResultWrapper';

const defaultPCN = (): PCN => ({
  barcode: '',
  shortCode: '',
  creditNoteType: '',
  expires: '',
  id: '',
  trxReference: '',
  message: '',
  pdfUrl: '',
  status: '',
  total: 0,
  created: '',
  imgUrl: '',
});

export interface PreTransaction {
  country: string;
  store: string;
  pos: string;
  trxDate: string;
  trx: string;
  paymentMethods: Array<string>;
  items?: Array<Item>;
  currency: string;
  subtotal: number;
  taxes: number;
  total: number;
}

export interface PreCreditNoteData {
  docType: string,
  doc: string;
  voucher: VoucherInput;
  type: RequestType;
  transaction: PreTransaction;
  items: Array<ItemWithQuantity>,
  customer: CustomerInfo;
}

const defaultTrx = (): PreTransaction => ({
  country: '',
  currency: '',
  paymentMethods: [],
  pos: '',
  store: '',
  subtotal: 0,
  taxes: 0,
  total: 0,
  trxDate: '',
  trx: '',
  items: [],
});

@Module({ name: 'preCreditNote', namespaced: true })
export default class PreCreditNote extends VuexModule {
  public locale: Locales = defaultLocale;

  public processing = false;

  public error: ResultError = {
    id: '',
    msg: '',
    type: ErrorType.NoError,
  };

  public result: PCN = defaultPCN();

  public data: PreCreditNoteData = {
    docType: '', // window.localStorage.getItem('documentType') || '',
    doc: '', // window.localStorage.getItem('document') || '',
    transaction: defaultTrx(),
    type: RequestType.NOT_SELECTED,
    items: [],
    voucher: DefaultVoucherInput(),
    customer: DefaultCustomerInfo(),
  };

  @Mutation
  public setLocale(locale: Locales): void {
    this.locale = locale;
  }

  @Mutation
  public setDocumentType(docType: string): void {
    // if (docType) window.localStorage.setItem('documentType', docType);
    this.data.docType = docType;
  }

  @Mutation
  public setDocument(doc: string): void {
    // if (doc) window.localStorage.setItem('document', doc);
    this.data.doc = doc;
  }

  @Mutation
  public setItems(items: Array<ItemWithQuantity>): void {
    this.data.items = items;
  }

  @Mutation
  public setCustomerData(customer: CustomerInfo): void {
    this.data.customer = customer;
  }

  @Mutation
  public setRequestType(type: RequestType): void {
    this.data.type = type;
  }

  @Mutation
  public setVoucherInput(input: VoucherInput): void {
    this.data.voucher = input;
  }

  @Mutation
  public setProcessing(flag: boolean): void {
    this.processing = flag;
  }

  @Mutation
  public setError(err: ResultError): void {
    this.error = err;
  }

  @Mutation
  public setTransaction(data: Transaction): void {
    this.data.transaction = {
      ...data,
      pos: data.pos.toString(),
      trx: data.trx.toString(),
      store: data.store.toString(),
    };
  }

  @Mutation
  public setPreCreditNote(data: PCN): void {
    this.result = data;
  }

  @Mutation
  public setCanceledPreCreditNote(data: PCN): void {
    this.result = data;
  }

  @Mutation
  public resetTransaction(): void {
    this.data.transaction = defaultTrx();
    this.data.items = [];
    this.data.customer = DefaultCustomerInfo();
  }

  @Mutation
  public clearData(): void {
    this.data.customer = DefaultCustomerInfo();
    this.data.items = [];
    this.data.transaction = defaultTrx();
    this.data.type = RequestType.NOT_SELECTED;
    this.data.voucher = DefaultVoucherInput();
  }
}
