import { AutoComplete, PreCreditNote as PCN } from 'client-nc-sdk';
import {
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { ErrorType } from '@/core/provider/lib/ErrorType';
import { Locales } from '@/i18n/locales';
import { defaultLocale } from '@/i18n';
import { ResultError } from '@/core/provider/lib/ResultWrapper';
import { DefaultProvider } from '@/core/provider';

@Module({ name: 'history', namespaced: true })
export default class History extends VuexModule {
  public locale: Locales = defaultLocale;

  public docType = '';

  public docNumber = '';

  public autoComplete: AutoComplete = {};

  public processing = false;

  public error: ResultError = {
    id: '',
    msg: '',
    type: ErrorType.NoError,
  };

  public requests: Array<PCN> = [];

  public get activeRequests(): Array<PCN> {
    // TODO: Status should be enumerated.
    return this.requests.filter((x) => x.status === 'PENDING');
  }

  public get maxRequestsAllowed(): number {
    return parseInt(DefaultProvider.lastParameters()?.MAX_PENDING_REQUESTS_PER_SESSION || '3', 10) || 3;
  }

  @Mutation
  public setDocType(docType: string): void {
    this.docType = docType;
  }

  @Mutation
  public setDocNumber(doc: string): void {
    this.docNumber = doc;
  }

  @Mutation
  public setHistory(data: Array<PCN>): void {
    this.requests = data;
  }

  @Mutation
  public setAutocomplete(data: AutoComplete): void {
    this.autoComplete = data;
  }

  @Mutation
  public setProcessing(flag: boolean): void {
    this.processing = flag;
  }

  @Mutation
  public setError(e: ResultError): void {
    this.error = e;
  }

  @Mutation
  public setLocale(locale: Locales): void {
    this.locale = locale;
  }

  @Mutation
  public setCanceledPreCreditNote(data: PCN): void {
    const elems = this.requests.filter((x) => x.id !== data.id);
    // TODO: Check date sorting.
    this.requests = elems.sort((x, y) => parseFloat(y.expires) - parseFloat(x.expires));
    elems.push(data);
  }
}
