import Vue from 'vue';
import VueI18n from 'vue-i18n';
import smoothscroll from 'smoothscroll-polyfill';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { I18nCls, I18nFn } from '@/i18n/i18nPlugin';
import { messages, defaultLocale } from '@/i18n';
import App from './App.vue';
import router from './router';
import store from './store';

// CSS Stuff
import './assets/main.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import { DefaultProvider } from './core/provider';
import { ResultWrapper } from './core/provider/lib/ResultWrapper';
import { ParamMap } from './core/provider/lib/ProviderInterfaces';

// Polyfill
smoothscroll.polyfill();

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(I18nFn);

DefaultProvider
  .listParameters({ locale: defaultLocale })
  .then((data: ResultWrapper<ParamMap>) => {
    // Parent Url
    const mode = data.result ? data.result.ENVIRONMENT : '';
    if (mode !== undefined && mode.trim() !== '') {
      I18nCls.environment = mode;
    }

    // Recaptcha
    Vue.use(VueReCaptcha, {
      siteKey: data.result ? data.result.GOOGLE_RECAPTCHA_SITE_KEY : '',
      loaderOptions: {
        useEnterprise: true,
        autoHideBadge: true,
      },
    });
  })
  .finally();

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
