var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"25px"}},[_vm._m(0),_c('div',{ref:"topSection",staticClass:"top-banner"},[_c('img',{staticClass:"intro-img",attrs:{"alt":_vm.$t('home.title'),"src":require("../assets/img/home-illustration.svg")}}),_c('h4',{class:{
        title: true,
        'hide-in-mobile': _vm.canSelect,
      }},[_vm._v(" "+_vm._s(_vm.$t('history.title'))+" ")]),_c('div',{staticClass:"section-remark"},[_vm._v(_vm._s(_vm.$t('history.remarks')))]),_c('h5',[_vm._v(_vm._s(_vm.$t('history.instructions')))])]),_c('div',{staticClass:"history-grid"},[_c('div',{staticClass:"headers columns is-mobile is-multiline is-variable is-1"},[_c('div',{staticClass:"column is-third info-key thead"},[_vm._v(" "+_vm._s(_vm.$t('history.headerRequest'))+" ")]),_c('div',{staticClass:"column is-third info-key thead"},[_vm._v(" "+_vm._s(_vm.$t('history.headerDate'))+" ")]),_c('div',{staticClass:"column is-third info-value thead"},[_vm._v(" "+_vm._s(_vm.$t('history.headerStatus'))+" ")])]),_vm._l((_vm.requests),function(item){return _c('a',{key:item.id,staticClass:"item columns is-mobile is-variable is-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.seeDetails(item)}}},[_c('div',{staticClass:"column info-key"},[_c('span',[_vm._v(_vm._s(_vm.$t('history.requestTitle')))]),_c('br'),_c('b',[_vm._v(_vm._s(item.shortCode))])]),_c('div',{staticClass:"column info-date"},[_vm._v(" "+_vm._s(_vm.getStringDate(item.created ? item.created : item.expires))+" ")]),_c('div',{staticClass:"column is-one-quarter info-value"},[_c('span',{class:{
            tag: true,
            'is-light': true,
            'is-success': _vm.isRequestCompleted(item),
            'is-warning': _vm.isRequestPending(item),
            'is-danger': _vm.isRequestCanceled(item),
          }},[_vm._v(" "+_vm._s(_vm.statusName(item))+" ")])])])})],2),(!_vm.canSelect)?_c('submit-btn',{attrs:{"text":_vm.$t('history.newRequest')},on:{"click":function($event){return _vm.selectType()}}}):_vm._e(),(!_vm.canSelect)?_c('link-item',{attrs:{"text":_vm.$t('history.back')},on:{"click":function($event){return _vm.goBack()}}}):_vm._e(),(_vm.canSelect)?_c('div',[_c('div',{staticClass:"separator"}),_c('div',{staticClass:"instructions"},[_vm._v("Seleccione un tipo de solicitud:")]),_c('div',{ref:"tsel",staticClass:"columns is-variable is-multiline is-mobile is-1",staticStyle:{"margin":"25px 0"},attrs:{"id":"rtype-selector"}},[_c('r-type-button',{attrs:{"src":require('../assets/img/ic_full_refund.svg'),"title":_vm.$t('home.fullRefund')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.FULL_REFUND)}}}),_c('r-type-button',{attrs:{"src":require('../assets/img/ic_partial_refund.svg'),"title":_vm.$t('home.partialRefund')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.PARTIAL_REFUND)}}}),_c('r-type-button',{attrs:{"src":require('../assets/img/ic_swap.svg'),"title":_vm.$t('home.swap')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.SWAP)}}}),_c('r-type-button',{attrs:{"src":require('../assets/img/ic_price_error.svg'),"title":_vm.$t('home.priceError')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.PRICE_ERROR)}}})],1),_c('link-item',{attrs:{"text":_vm.$t('history.cancel')},on:{"click":function($event){_vm.canSelect = false}}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-logo"},[_c('img',{attrs:{"src":require("../assets/img/tottus.svg")}})])}]

export { render, staticRenderFns }