import { ListPreCreditNotes, PreCreditNote, Transaction } from 'client-nc-sdk';
import moment from 'moment';
import { VoucherInput } from '../VoucherInput';
import sleep from '../helpers/Sleep';
import { ResultWrapper } from './lib/ResultWrapper';
import { ErrorType } from './lib/ErrorType';
import { CancelPCNObs, HistoryObs, IPCNProvider, ParamMap, ParamsObs, PreCreditNoteObs, TransactionObs } from './lib/ProviderInterfaces';
import { CustomerInfo } from '../CustomerInfo';
import { ItemWithQuantity } from '../ItemWithQuantity';
import RequestType from '../RequestType';
import { PreTransaction } from '@/store/modules/PreCreditNote';

let lastResult: PreCreditNote | undefined;

export class MockProvider implements IPCNProvider {
  public cleanResult(): void {
    lastResult = undefined;
  }

  public async generatePreCreditNote(
    docType: string,
    doc: string,
    type: RequestType,
    trx: PreTransaction,
    items: ItemWithQuantity[],
    c: CustomerInfo,
    captcha: string,
    obs: PreCreditNoteObs,
  ): Promise<ResultWrapper<PreCreditNote>> {
    obs.setProcessing(true);
    await sleep(500);
    const result = new ResultWrapper<PreCreditNote>({
      barcode: 'https://symbology.dev/assets/barcodes/barcode_14.png',
      shortCode: '01234567',
      trxReference: '',
      message: '',
      pdfUrl: 'http://localhost:8080/sample.pdf',
      creditNoteType: type,
      total: items.reduce((a, b) => a + b.quantity * (b.item.amount / b.item.quantity), 0),
      expires: moment().add(2, 'hours').add(32, 'minutes').format(), // '2022-03-07T17:40:30.000Z',
      id: 'f59623f7-43f4-4212-b6ca-703565af4189',
      status: 'PENDING',
      created: '',
      imgUrl: '',
    });

    lastResult = result.result;
    obs.setProcessing(false);
    if (result.result) obs.setPreCreditNote(result.result);
    return result;
  }

  public lastParameters(): ParamMap | undefined {
    const d: ParamMap = {};
    d.GOOGLE_RECAPTCHA_SITE_KEY = process.env.VUE_APP_GCAPTCHA_KEY;
    d.MAX_PENDING_REQUESTS_PER_SESSION = '1';
    return d;
  }

  public async listParameters(obs: ParamsObs): Promise<ResultWrapper<ParamMap>> {
    await sleep(500);
    const d: ParamMap = {};
    d.GOOGLE_RECAPTCHA_SITE_KEY = process.env.VUE_APP_GCAPTCHA_KEY;
    d.MAX_PENDING_REQUESTS_PER_SESSION = '1';
    d.LOCALE = obs.locale;
    d.ENVIRONMENT = 'PRD';
    return new ResultWrapper(d);
  }

  public async listRequestHistory(obs: HistoryObs): Promise<ResultWrapper<ListPreCreditNotes>> {
    if (!lastResult || obs.docNumber === '01234568') {
      obs.setHistory([]);
      return new ResultWrapper<ListPreCreditNotes>({ autoComplete: {}, list: [] });
    }

    obs.setHistory([lastResult]);
    return new ResultWrapper<ListPreCreditNotes>({
      autoComplete: {},
      list: [lastResult],
    });
  }

  public async cancelPreCreditNote(
    docType: string,
    doc: string,
    pcn: PreCreditNote,
    obs: CancelPCNObs,
  ): Promise<ResultWrapper<PreCreditNote>> {
    obs.setProcessing(true);
    await sleep(500);
    if (pcn.id.trim() === '') {
      obs.setProcessing(false);
      obs.setError({
        id: '',
        msg: 'Bad Id.',
        type: ErrorType.BadRequest,
      });
      return new ResultWrapper<PreCreditNote>(undefined, 'Bad Id.', 400);
    }

    if (lastResult === undefined || lastResult.status === 'CANCELED') {
      obs.setProcessing(false);
      obs.setError({
        id: '',
        msg: 'Pre Credit Note not found.',
        type: ErrorType.NotFound,
      });
      return new ResultWrapper<PreCreditNote>(undefined, 'Pre Credit Note not found.', 404);
    }

    await sleep(500);
    lastResult.status = 'CANCELED';
    obs.setCanceledPreCreditNote(lastResult);
    return new ResultWrapper(lastResult);
  }

  public async getTransaction(
    input: VoucherInput,
    captcha: string,
    obs: TransactionObs,
  ): Promise<ResultWrapper<Transaction>> {
    const country = [obs];
    if (!country) return new ResultWrapper<Transaction>(undefined, 'No existe la transacción.');
    obs.setProcessing(true);
    await sleep(500);
    if (input.trx === '1111') {
      const data = new ResultWrapper<Transaction>({
        country: 'PE',
        currency: 'PEN',
        paymentMethods: ['VISA', 'FPAY'],
        pos: parseInt(input.pos, 10),
        store: parseInt(input.local, 10),
        subtotal: 150000.00,
        taxes: 50000.00,
        total: 200000.00,
        trxDate: '2021-08-05T00:00:00.000Z',
        trx: parseInt(input.trx, 10),
        items: [
          {
            id: '1234567890123',
            amount: 120000,
            description: 'COCOA KARA 200ml',
            quantity: 1,
            unit: 'NIU',
            availableAmount: 120000,
            availableQuantity: 1,
          },
          {
            id: '1234567890123',
            amount: 120000,
            description: 'COCOA KARA 200ml',
            quantity: 1,
            unit: 'NIU',
            availableAmount: 120000,
            availableQuantity: 1,
          },
          {
            id: '4567890123565',
            amount: 25500,
            description: 'POLO BASIC XL',
            quantity: 1,
            unit: 'NIU',
            availableAmount: 25500,
            availableQuantity: 1,
          },
          {
            id: '0980765432158',
            amount: 55400,
            description: 'MOCKINGBIRD GLUE XXS',
            quantity: 1,
            unit: 'NIU',
            availableAmount: 55400,
            availableQuantity: 1,
          },
          {
            id: '6433567890123',
            amount: 3.2600000000000002,
            description: 'TALLARIN VOFOP2 250MG',
            quantity: 1.4160000000000001,
            unit: 'NIU',
            availableAmount: 3.2600000000000002,
            availableQuantity: 1.4160000000000001,
          },
          {
            id: '8433567890123',
            amount: 40206,
            description: 'TALLARIN OTRO 250MG',
            quantity: 3.5,
            unit: 'NIU',
            availableAmount: 40206,
            availableQuantity: 3.5,
          },
        ],
      });

      obs.setProcessing(false);
      if (data.result) obs.setTransaction(data.result);
      return data;
    }

    const err = 'No existe la transacción.';
    obs.setProcessing(false);
    obs.setError({
      id: '',
      msg: err,
      type: ErrorType.NotFound,
    });
    return new ResultWrapper<Transaction>(undefined, err);
  }
}
