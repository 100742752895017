



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AlertModal extends Vue {
  private currentCallback: () => void = () => {};

  public message = '';

  public active = false;

  public ref = '';

  @Prop() private ok!: string;

  public completeModal(): void {
    this.hide();
  }

  public show(
    message: string,
    callback: () => void,
    ref: string | undefined = undefined,
  ): void {
    this.message = message;
    this.currentCallback = callback;
    this.active = true;
    this.ref = ref || '';
  }

  public hide(): void {
    this.currentCallback();
    this.currentCallback = () => {};
    this.active = false;
  }

  public remove(): void {
    this.$destroy();
    if (!this.$el) return;
    // eslint-disable-next-line
    if (!(this.$el as any).parentNode) return;
    // eslint-disable-next-line
    (this.$el as any).parentNode.removeChild(this.$el);
  }
}
