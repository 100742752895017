import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import DomHelper from '@/mixins/DomHelper';
import FloatingInput from '@/components/elements/FloatingInput.vue';
import { VoucherInput } from '@/core/VoucherInput';
import { ValidationObject } from '../components/lib/ValidationObject';

const defaultInput = (): VoucherInput => ({
  local: '',
  datetime: '',
  trx: '',
  pos: '',
  amount: '',
});

@Component({
  components: {
    FloatingInput,
  },
})
export default class VoucherForm extends mixins(DomHelper) {
  public imgWidth = 0;

  public error: VoucherInput = defaultInput();

  public validLocal: ValidationObject = { valid: undefined, err: '' };

  public validDate: ValidationObject = { valid: undefined, err: '' };

  public validTrx: ValidationObject = { valid: undefined, err: '' };

  public validPos: ValidationObject = { valid: undefined, err: '' };

  public validAmount: ValidationObject = { valid: undefined, err: '' };

  @Prop() private value!: VoucherInput;

  public mounted(): void {
    this.onResize = this.updateSizes;
    setTimeout(() => {
      this.focusSequence(1);
    }, 1000);
  }

  public validateAll(): boolean {
    const vl = this.validateLocal(false);
    const vdt = this.validateDateTime(false);
    const vtx = this.validateTrx(false);
    const vp = this.validatePos(false);
    const va = this.validateAmount(false);

    if (!vl) this.nextInput(0);
    else if (!vp) this.nextInput(1);
    else if (!vdt) this.nextInput(2);
    else if (!vtx) this.nextInput(3);
    else if (!va) this.nextInput(4);

    return vl && vdt && vtx && vp && va;
  }

  public checkStandings(): void {
    const vl = this.validLocal.valid === true;
    const vdt = this.validDate.valid === true;
    const vtx = this.validTrx.valid === true;
    const vp = this.validPos.valid === true;
    const va = this.validAmount.valid === true;
    const valid = vl && vdt && vtx && vp && va;
    this.$emit(valid ? 'valid' : 'invalid');
  }

  public validateLocal(goNext = true): boolean {
    if (!this.value) return false;
    const input = this.value.local;
    this.validLocal = { valid: true, err: '' };
    if (input.length < 2 || input.length > 4 || !/^\d+$/.test(input)) {
      const err = this.$t('voucherLookup.localError').toString();
      this.validLocal = { valid: false, err };
    }

    if (goNext) this.nextInput(1);
    this.checkStandings();
    return this.validLocal.valid || false;
  }

  public validatePos(goNext = true): boolean {
    if (!this.value) return false;
    const input = this.value.pos;
    this.validPos = { valid: true, err: '' };
    if (input.length < 1 || input.length > 4 || !/^\d+$/.test(input)) {
      const err = this.$t('voucherLookup.posError').toString();
      this.validPos = { valid: false, err };
    }

    if (goNext) this.nextInput(2);
    this.checkStandings();
    return this.validPos.valid || false;
  }

  public validateDateTime(goNext = true): boolean {
    if (!this.value) return false;
    const input = this.value.datetime;
    this.validDate = { valid: true, err: '' };
    if (input.length !== 10 || !/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(input)) {
      this.validDate = { valid: false, err: this.$t('voucherLookup.dateError').toString() };
    } else {
      let formatValid = false;
      let [day, month, year] = [0, 0, 0];
      try {
        const parts = input.split('-');
        [day, month, year] = parts.map((x) => parseInt(x, 10));
        if (day < 1 || day > 31) throw Error('Invalid day number');
        if (month < 1 || month > 12) throw Error('Invalid month number');
        if (year < 999) throw Error('Invalid year number');
        formatValid = true;
      } catch {
        this.validDate = { valid: false, err: this.$t('voucherLookup.dateInvalidFormat').toString() };
      }

      if (formatValid) {
        let incoherent = false;
        let tooOld = false;
        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

        const oldest = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
        if (year < oldest.getFullYear()) tooOld = true;
        if (!(month > 0 && month < 13)) incoherent = true;
        if (day < 0 || day > monthLength[month - 1]) incoherent = true;

        if (incoherent) {
          this.validDate = { valid: false, err: this.$t('voucherLookup.dateInvalidDate').toString() };
        }

        if (tooOld) {
          this.validDate = { valid: false, err: this.$t('voucherLookup.dateTooOld').toString() };
        }
      }
    }

    if (input.length !== 10 || !/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(input)) {
      const err = this.$t('voucherLookup.dateError').toString();
      this.validDate = { valid: false, err };
    }

    if (goNext) this.nextInput(3);
    this.checkStandings();
    return this.validDate.valid || false;
  }

  public validateTrx(goNext = true): boolean {
    if (!this.value) return false;
    const input = this.value.trx;
    this.validTrx = { valid: true, err: '' };
    if (input.length < 2 || input.length > 8 || !/^\d+$/.test(input)) {
      const err = this.$t('voucherLookup.trxError').toString();
      this.validTrx = { valid: false, err };
    }

    if (goNext) this.nextInput(4);
    this.checkStandings();
    return this.validTrx.valid || false;
  }

  public validateAmount(goNext = true): boolean {
    if (!this.value) return false;
    const input = this.value.amount;
    this.validAmount = { valid: true, err: '' };
    if (!this.isNumeric(input)) {
      const err = this.$t('voucherLookup.amountError').toString();
      this.validAmount = { valid: false, err };
    }

    if (goNext) this.nextInput(5);
    this.checkStandings();
    return this.validAmount.valid || false;
  }

  public focusSequence(n: number): void {
    // eslint-disable-next-line
    const input = this.$refs[`f${n}`] as FloatingInput;
    if (!input || typeof input.manualFocus !== 'function') return;
    input.manualFocus();
  }

  public blurSequence(n: number): void {
    // eslint-disable-next-line
    const input = this.$refs[`f${n}`] as FloatingInput;
    if (!input || typeof input.manualFocus !== 'function') return;
    input.manualBlur();
  }

  public updateSizes(): void {
    // eslint-disable-next-line
    const form = this.$refs.voucher as any;
    if (!form) return;
    this.imgWidth = form.clientWidth || 0;
  }

  public isNumeric(str: string): boolean {
    if (typeof str !== 'string') return false;
    return !Number.isNaN(parseFloat(str));
  }

  public nextInput(index: number): void {
    this.$emit('input', this.value);
    if (index >= 5) {
      this.blurSequence(index);
      return;
    }

    this.focusSequence(index + 1);
  }
}
