import _Vue from 'vue';
import { ResultError } from '@/core/provider/lib/ResultWrapper';

export class I18nCls {
  public static environment = 'PRD';

  public getErrorMsg(vue: _Vue, prefix: string, err: ResultError): string {
    const str = vue.$t(`${prefix}.errors.${err.type}`);
    if (!str || str === `${prefix}.errors.${err.type}`) {
      if (!err.msg) {
        return vue.$t('global.unknownError').toString();
      }

      if (typeof err.msg === 'string') {
        return err.msg;
      }

      return err.msg ? err.msg : vue.$t('global.unknownError').toString();
    }

    return str.toString();
  }

  public getBoolean(vue: _Vue, fn: string, data: string): { result: boolean, extra: string } {
    try {
      const isValid = vue.$t(`fn.${fn}`, { data });
      return { result: isValid === 'ok', extra: isValid !== 'ok' ? isValid.toString() : '' };
    } catch {
      return { result: false, extra: '??' };
    }
  }

  public getString(vue: _Vue, fn: string, data: string): string {
    return vue.$t(`fn.${fn}`, { data }).toString();
  }
}

export function I18nFn(Vue: typeof _Vue): void {
  // eslint-disable-next-line
  Vue.prototype.$tfn = new I18nCls();
}
