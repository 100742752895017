import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import PreCreditNoteHandler from './PreCreditNoteHandler';

@Component
export default class CaptchaHandler extends mixins(PreCreditNoteHandler) {
  public captchaLoading = false;

  public async processCaptcha(): Promise<string | undefined> {
    this.captchaLoading = true;
    try {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login');
      if (!token) {
        throw new Error(this.$t('voucherRequest.invalidCaptcha').toString());
      }

      return token;
    } catch (ex) {
      return undefined;
    } finally {
      this.captchaLoading = false;
    }
  }
}
