













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObject } from '../lib/ValidationObject';

@Component
export default class FloatingInput extends Vue {
  @Prop() private top!: number;

  @Prop() private left!: number;

  @Prop({ default: false }) private swapDot!: boolean;

  @Prop() private help!: string;

  @Prop() private refWidth!: number;

  @Prop() private value!: string;

  @Prop({ default: false }) private isDate!: boolean;

  @Prop({ default: false }) private isAmount!: boolean;

  @Prop({ default: { err: '', valid: undefined } })
  private validity!: ValidationObject;

  @Prop({ default: 4 }) private maxDigits!: number;

  @Prop({ default: 'XXXX' }) private inputHelp!: string;

  public refId = '';

  public focused = false;

  public txtHeight = 0;

  public fontHeight = 0;

  public bubbleSeparation = 0;

  public get isValid(): boolean {
    return this.validity.valid === true;
  }

  public get withErrors(): boolean {
    return this.validity.valid === false;
  }

  // eslint-disable-next-line
  public get inputStyle() {
    const inputWidth = 12 + this.maxDigits * 10;
    return {
      width: `${inputWidth}px`,
    };
  }

  // eslint-disable-next-line
  public get leftDotStyle() {
    return {
      height: `${this.txtHeight}px`,
      'font-size': `${this.fontHeight}px`,
      'line-height': `${this.txtHeight}px`,
      'padding-right': `${this.txtHeight}px`,
    };
  }

  // eslint-disable-next-line
  public get rightDotStyle() {
    return {
      height: `${this.txtHeight}px`,
      'font-size': `${this.fontHeight}px`,
      'line-height': `${this.txtHeight}px`,
    };
  }

  @Watch('value', { immediate: true, deep: true })
  public checkInput(newVal: string, oldVal: string): void {
    if (newVal === '') {
      return;
    }

    if (this.isDate) {
      const filtered = this.replaceAll(newVal, '-', '');
      const datesOnly = /^\d+$/.test(filtered);
      if (!datesOnly) {
        this.$emit('input', oldVal);
        return;
      }

      const day = filtered.substring(0, 2) || '';
      const month = filtered.substring(2, 4) || '';
      const year = filtered.substring(4, 8) || '';
      let aggr = `${day}`;
      aggr += month.length > 0 ? `-${month}` : `${month}`;
      aggr += year.length > 0 ? `-${year}` : `${year}`;

      if (aggr === newVal) return;
      this.$emit('input', aggr);
      return;
    }

    if (this.isAmount) {
      const numbersOnly = /^[0-9.]+$/.test(newVal);
      if (!numbersOnly) this.$emit('input', oldVal);
      return;
    }

    const digitsOnly = /^[0-9]+$/.test(newVal);
    if (!digitsOnly) this.$emit('input', oldVal);
  }

  @Watch('refWidth', { immediate: true, deep: true })
  public updateSizes(newVal: number): void {
    this.txtHeight = newVal * 0.06;
    this.fontHeight = newVal * 0.04;
    this.bubbleSeparation = newVal * 0.06 + 20;
  }

  @Watch('value', { immediate: true, deep: true })
  checkQuantity(): void {
    this.validity.valid = undefined;
  }

  public manualFocus(): void {
    // eslint-disable-next-line
    const mainInput = this.$refs[this.refId] as any;
    if (mainInput) mainInput.focus();
  }

  public manualBlur(): void {
    // eslint-disable-next-line
    const mainInput = this.$refs[this.refId] as any;
    mainInput.blur();
  }

  public mounted(): void {
    this.refId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);
  }

  private escapeRegExp(str: string): string {
    return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  }

  private replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  private showInfo(): void {
    this.focused = true;
  }

  private hideInfo(): void {
    this.focused = false;
    this.$emit('blur');
  }

  private checkEnter(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.$emit('finished');
    }
  }
}
