
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObject } from '../lib/ValidationObject';

@Component
export default class DirectInput extends Vue {
  @Prop() private placeholder!: string;

  @Prop() private helpText!: string;

  @Prop() private value!: string;

  @Prop({ default: false }) private isOptional!: boolean;

  @Prop({ default: 60 }) private inputLength!: number;

  @Prop({ default: { err: '', valid: undefined } })
  private validity!: ValidationObject;

  public focused = false;

  public refId = '';

  public content = '';

  public get hasError(): boolean {
    return this.validity.valid === false;
  }

  @Watch('value', { immediate: true, deep: true })
  private updateContent(newVal: string) {
    this.content = newVal;
  }

  @Watch('content', { immediate: true, deep: true })
  private updateModel(newVal: string) {
    if (newVal !== this.value) {
      this.$emit('input', this.content);
    }
  }

  public manualFocus(): void {
    // eslint-disable-next-line
    const mainInput = this.$refs[this.refId] as any;
    this.$emit('input', this.content);
    if (mainInput) mainInput.focus();
  }

  public manualBlur(): void {
    // eslint-disable-next-line
    const mainInput = this.$refs[this.refId] as any;
    this.$emit('input', this.content);
    mainInput.blur();
  }

  public mounted(): void {
    this.refId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);
    setTimeout(() => {
      if (this.content !== '') {
        this.$emit('input', this.content);
        this.$emit('finished', this.content);
      }
    }, 500);
  }

  private showInfo(): void {
    this.$emit('input', this.content);
    this.focused = true;
  }

  private hideInfo(): void {
    this.focused = false;
    this.$emit('blur');
    this.$emit('input', this.content);
  }

  private checkEnter(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.$emit('input', this.content);
      this.$emit('finished');
    }
  }
}
