import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { Locales } from '@/i18n/locales';
import PreCreditNoteHandler from './PreCreditNoteHandler';

@Component
export default class Localizer extends mixins(PreCreditNoteHandler) {
  private observing = false;

  public country = '';

  public observePaths(): void {
    this.observing = true;
    this.checkLocale();
  }

  public localizeRoute(str: string): string {
    if (!this.$route || !this.$route.params) return str;
    const { country } = this.$route.params;
    if (!country) return str;
    if (str === '' || str === './' || str === '/') {
      return `/${country}`;
    }

    const cleanPath = str.startsWith('/') ? str.substring(1) : str;
    return `/${country}/${cleanPath}`;
  }

  @Watch('$route', { immediate: true, deep: true })
  public onUrlChange(): void {
    if (!this.observing) return;
    this.checkLocale();
  }

  public amountWithCurrency(amount: number | string): string {
    const prefix = this.$t('currency.prefix');
    const suffix = this.$t('currency.suffix');
    const maxDecs = Number.parseInt(this.$t('currency.maxDecimals').toString(), 10);
    const x = this.thousandSeparator(this.financial(amount, maxDecs));
    return `${prefix}${x}${suffix}`;
  }

  public checkLocale(): void {
    if (!this.$route || !this.$route.params) return;
    const { country } = this.$route.params;
    this.country = country.toUpperCase();
    if (country) this.$root.$i18n.locale = `es-${country.toUpperCase()}`;
    this.history.setLocale(this.getLocale(country));
    this.store.setLocale(this.getLocale(country));
  }

  private financial(x: number | string, decimals: number): string {
    const s = typeof x === 'string' ? Number.parseFloat(x) : x;
    const val = s.toFixed(decimals);
    return val.replace(',', this.$t('currency.decimalSep').toString()).replace('.', this.$t('currency.decimalSep').toString());
  }

  private thousandSeparator(x: string): string {
    const sep = this.$t('currency.thousandsSep').toString();
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    return parts.join('.');
  }

  private getLocale(country: string | undefined): Locales {
    if (!country) return Locales.ES_CL;
    switch (country.toUpperCase()) {
      case 'CL': return Locales.ES_CL;
      case 'PE': return Locales.ES_PE;
      default: return Locales.ES_CL;
    }
  }
}
