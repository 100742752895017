





























































import { Item, Transaction } from 'client-nc-sdk';
import { mixins } from 'vue-class-component';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RequestType from '@/core/RequestType';
import Localizer from '@/mixins/Localizer';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import QuantityModal from './elements/QuantityModal.vue';

interface SelectableItem extends Item {
  uid: number;
}

@Component
export default class ProductSelection extends mixins(
  PreCreditNoteHandler,
  Localizer,
) {
  @Prop() private value!: Array<{ uid: number; item: Item; quantity: number }>;

  @Prop() private trx!: Transaction;

  @Prop() private type!: RequestType;

  @Prop({ default: false }) private readonly!: boolean;

  public get hideTotals(): boolean {
    return this.type === RequestType.PRICE_ERROR;
  }

  public get allItems(): Array<SelectableItem> {
    let counter = 0;
    if (this.readonly) {
      return this.value.map((x) => {
        counter += 1;
        return { uid: counter, ...x.item };
      });
    }

    return (
      this.trx.items?.map((x) => {
        counter += 1;
        return { uid: counter, ...x };
      }) || []
    );
  }

  public get totalSelected(): number {
    return this.value.reduce(
      (a, b) => a + (b.item.amount / b.item.quantity) * b.quantity,
      0,
    );
  }

  public fixNumber(amount: number | undefined): string {
    if (!amount) return '0';
    if (amount === Math.round(amount)) {
      return amount.toString();
    }

    return amount.toFixed(3);
  }

  public selectItem(item: SelectableItem): void {
    if (this.readonly) return;
    const selected = this.isSelected(item);
    if (selected) {
      const newvalue = this.value.filter((x) => x.uid !== item.uid); // x.item.id !== item.id
      this.$emit('input', newvalue);
    } else {
      if (
        this.type === RequestType.PRICE_ERROR ||
        !Number.isInteger(item.availableQuantity || 1)
      ) {
        const newvalue = this.value.filter((x) => x.uid !== item.uid); //  x.item.id !== item.id
        newvalue.push({
          uid: item.uid,
          item,
          quantity: item.availableQuantity || 1,
        });
        this.$emit('input', newvalue);
        return;
      }

      if ((item.availableQuantity || 1) > 1) {
        const q = item.availableQuantity || 1;
        const desc = this.fixVerb(this.$t('voucherInfo.quantityInstructions'));

        const ComponentClass = Vue.extend(QuantityModal);
        const instance = new ComponentClass({
          propsData: {
            ok: this.$t('global.ok').toString(),
          },
        });

        const qModal = instance as QuantityModal;
        qModal.$mount();
        const parent = window.document.getElementById('dialog');
        if (parent) parent.appendChild(instance.$el);

        qModal.show(q, item.description, desc, (quantity) => {
          if (quantity > 0) {
            const newvalue = this.value.filter((x) => x.uid !== item.uid);
            newvalue.push({ uid: item.uid, item, quantity });
            this.$emit('input', newvalue);
          }

          qModal.remove();
        });

        return;
      }

      const newvalue = this.value.filter((x) => x.uid !== item.uid);
      newvalue.push({ uid: item.uid, item, quantity: 1 });
      this.$emit('input', newvalue);
    }

    this.$emit('update');
  }

  private isSelected(item: SelectableItem): boolean {
    const selected = this.value.find((x) => x.uid === item.uid);
    return selected !== undefined;
  }
}
