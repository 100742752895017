var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"topSection",staticClass:"top-banner"},[_vm._m(0),_c('img',{staticClass:"intro-img",attrs:{"alt":_vm.$t('home.title'),"src":require("../assets/img/home-illustration.svg")}}),_c('h4',{class:{
        title: true,
        'hide-in-mobile': _vm.canSelect,
      }},[_vm._v(" "+_vm._s(_vm.$t('home.title'))+" ")])]),_c('div',{staticClass:"custom-padding",style:({ height: (_vm.midPadding + "px"), width: '100%' })}),_c('form',{ref:"bottomSection",staticClass:"form-initial-request",on:{"submit":function($event){$event.preventDefault();return _vm.checkAndContinue.apply(null, arguments)}}},[_c('div',{ref:"reqForm",staticClass:"inner-form"},[(_vm.showDocTytpes)?_c('div',{class:{
          select: true,
          'doctype-selector': true,
          'hide-in-mobile': _vm.canSelect,
        }},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.docType),expression:"docType"}],attrs:{"readonly":_vm.history.processing},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.docType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.updateDocInput]}},_vm._l((_vm.validDocTypes),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)]):_vm._e(),_c('div',{class:{
          columns: true,
          'is-gapless': true,
          'is-mobile': true,
          'doc-box': true,
          'hide-in-mobile': _vm.canSelect,
        }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.submitted && !_vm.docOk)?_c('div',{staticClass:"validation-box"},[_c('span',{staticClass:"tag is-danger is-light"},[_vm._v(" "+_vm._s(_vm.$t(_vm.docError))+" ")])]):_vm._e()]),_c('div',{staticClass:"column"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.docNumber),expression:"docNumber"}],staticClass:"input",attrs:{"type":"text","readonly":_vm.history.processing,"placeholder":_vm.docTypePlaceholder},domProps:{"value":(_vm.docNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.docNumber=$event.target.value}}})]),_c('div',{staticClass:"column is-narrow",staticStyle:{"width":"50px"}},[_c('a',{class:{
              'select-dnirut': true,
              button: true,
              'is-info': !_vm.docOk,
              'is-success': _vm.docOk,
            },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.checkAndContinue.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-chevron-right"})])])],1),(!_vm.history.processing)?_c('input',{ref:"reqsel",class:{
          'is-large': true,
          'is-fullwidth': true,
          button: true,
          'is-info': !_vm.docOk,
          'is-success': _vm.docOk,
        },attrs:{"type":"submit","id":"request-selection"},domProps:{"value":_vm.$t('home.submitDoc')}}):_c('spinner'),(_vm.canSelect)?_c('div',{staticClass:"columns is-variable is-multiline is-mobile is-1",attrs:{"id":"rtype-selector"}},[_c('r-type-button',{attrs:{"src":require('../assets/img/ic_full_refund.svg'),"title":_vm.$t('home.fullRefund')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.FULL_REFUND)}}}),_c('r-type-button',{attrs:{"src":require('../assets/img/ic_partial_refund.svg'),"title":_vm.$t('home.partialRefund')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.PARTIAL_REFUND)}}}),_c('r-type-button',{attrs:{"src":require('../assets/img/ic_swap.svg'),"title":_vm.$t('home.swap')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.SWAP)}}}),_c('r-type-button',{attrs:{"src":require('../assets/img/ic_price_error.svg'),"title":_vm.$t('home.priceError')},on:{"click":function($event){return _vm.selectRequestType(_vm.validTypes.PRICE_ERROR)}}})],1):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-logo"},[_c('img',{attrs:{"src":require("../assets/img/tottus.svg")}})])}]

export { render, staticRenderFns }