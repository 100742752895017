









































































































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { PreCreditNote as PCN } from 'client-nc-sdk';
import RTypeButton from '@/components/elements/RTypeButton.vue';
import Spinner from '@/components/elements/Spinner.vue';
import DomHelper from '@/mixins/DomHelper';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import RequestType from '@/core/RequestType';
import { DefaultProvider } from '@/core/provider';
import Localizer from '@/mixins/Localizer';
import Notifier from '@/mixins/Notifier';

@Component({
  components: {
    RTypeButton,
    Spinner,
  },
})
export default class Home extends mixins(
  PreCreditNoteHandler,
  DomHelper,
  Localizer,
  Notifier,
) {
  private srv = DefaultProvider;

  private bh = 0;

  private wh = 0;

  public docOk = false;

  public docError = '';

  public submitted = false;

  public canGoForward = false;

  public docType = '';

  public docNumber = '';

  public midPadding = 0;

  public validTypes = RequestType;

  public get canSelect(): boolean {
    return this.docOk && this.submitted && this.canGoForward;
  }

  public get activeRequests(): Array<PCN> {
    return this.history.activeRequests;
  }

  public get requests(): Array<PCN> {
    return this.history.requests;
  }

  public get validDocTypes(): Array<{ id: string; name: string }> {
    const items = this.$t('identity.docTypes').toString().split(',');
    if (!items.includes(this.docType)) {
      [this.docType] = items;
      this.docNumber = '';
    }

    return items.map((x) => ({
      id: x,
      name: this.$t(`identity.name${x}`).toString(),
    }));
  }

  public get docTypePlaceholder(): string {
    return this.$t(`identity.placeholder${this.docType}`).toString();
  }

  public get showDocTytpes(): boolean {
    return this.validDocTypes.length > 1;
  }

  public async checkAndContinue(): Promise<void> {
    if (this.history.processing) return;
    this.checkDocument(this.docType, this.docNumber);
    this.submitted = true;
    if (this.docOk) {
      const fdoc = this.$tfn.getString(this, 'cleanDoc', this.docNumber);
      this.store.setDocumentType(this.docType);
      this.store.setDocument(fdoc);
      this.history.setDocType(this.docType);
      this.history.setDocNumber(fdoc);
      await this.srv.listRequestHistory(this.history);
      if (this.history.requests.length > 0) {
        this.goToHistory();
      } else {
        const secondTime = this.canSelect;
        this.canGoForward = true;
        if (secondTime) {
          this.showMessage(this.$t('home.selectTypeToContinue'));
        }
      }
    }

    this.onResizeAsync();
  }

  public selectRequestType(rtype: RequestType): void {
    this.store.setRequestType(rtype);
    this.$router.push(this.localizeRoute('voucher-lookup'));
  }

  @Watch('docNumber', { immediate: true, deep: true })
  public evaluateDocNumber(newVal: string): void {
    this.checkDocument(this.docType, newVal);
    this.submitted = false;
    this.onResizeAsync();
  }

  public mounted(): void {
    this.onResize = this.calculateHeights;
    this.docNumber = this.store.data.doc;
    const storedType = this.store.data.docType;
    if (storedType) {
      this.docType = storedType;
    } else {
      this.docType = this.validDocTypes[0].id;
    }
    this.calculateHeights();
  }

  private goToHistory(): void {
    this.$router.push(this.localizeRoute('history'));
  }

  private updateDocInput() {
    this.docNumber = '';
  }

  private checkDocument(type: string, doc: string): boolean {
    const isValid = this.$tfn.getBoolean(this, 'validateDoc', `${type}:${doc}`);
    this.docOk = isValid.result;
    this.docError = isValid.extra;
    this.calculateHeights();
    return isValid.result;
  }

  private calculateHeights(): void {
    const { innerHeight } = window;
    const top = this.$refs.topSection as HTMLElement;
    const bottom = this.$refs.bottomSection as HTMLElement;
    if (!top || !bottom) return;
    const topHeight = top.clientHeight;
    const bottomHeight = bottom.clientHeight;
    let spaceLeft = innerHeight - topHeight - bottomHeight * 1.1;
    const limitSpace = this.canSelect ? 0 : 25;
    if (spaceLeft <= limitSpace) spaceLeft = limitSpace;
    const mb = Math.abs(this.midPadding - spaceLeft) / innerHeight;
    if (mb <= 0.025) return;
    this.midPadding = spaceLeft;
  }
}
