




































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { PreCreditNote as PCN } from 'client-nc-sdk';
import { mixins } from 'vue-class-component';
import moment from 'moment';
import DomHelper from '@/mixins/DomHelper';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import CaptchaHandler from '@/mixins/CaptchaHandler';
import Localizer from '@/mixins/Localizer';
import Breadcrumb, { BreadcrumbStep } from '@/components/Breadcrumb.vue';
import SubmitBtn from '@/components/elements/SubmitBtn.vue';
import LinkItem from '@/components/elements/LinkItem.vue';
import Spinner from '@/components/elements/Spinner.vue';
import Notifier from '@/mixins/Notifier';
import { DefaultProvider } from '@/core/provider';
import ConfirmModal from '@/components/elements/ConfirmModal.vue';
import DefaultPCN from '@/core/provider/lib/DefaultPCN';
import RequestStatus from '@/core/RequestStatus';
import TransactionDetails from '@/components/TransactionDetails.vue';

@Component({
  components: {
    Breadcrumb,
    SubmitBtn,
    LinkItem,
    Spinner,
  },
})
export default class CompleteRequest extends mixins(
  PreCreditNoteHandler,
  Notifier,
  DomHelper,
  Localizer,
  CaptchaHandler,
  TransactionDetails,
) {
  private intref = 0;

  private srv = DefaultProvider;

  public currentStep: BreadcrumbStep = BreadcrumbStep.CompleteRequest;

  public completed = false;

  public fromHistory = false;

  public preparingPdf = false;

  public pcn: PCN = DefaultPCN();

  public now: number = moment.now();

  public get isRequestPending(): boolean {
    return this.pcn.status.toUpperCase() === RequestStatus.PENDING;
  }

  public get isRequestCompleted(): boolean {
    return (
      this.pcn.status.toUpperCase() === RequestStatus.COMPLETED ||
      this.pcn.status.toUpperCase() === RequestStatus.PROCESSED
    );
  }

  public get isRequestCanceled(): boolean {
    return (
      this.pcn.status.toUpperCase() === RequestStatus.CANCELED ||
      this.pcn.status.toUpperCase() === RequestStatus.EXPIRED
    );
  }

  public get requestType(): string {
    const type = this.pcn.creditNoteType.toUpperCase();
    return this.$t(`global.type${type}`).toString();
  }

  public get statusName(): string {
    return this.$t(`global.status${this.pcn.status.toUpperCase()}`)
      .toString()
      .toUpperCase();
  }

  public done(): void {
    this.store.clearData();
    this.$router.push(this.localizeRoute('history'));
  }

  @Watch('completed')
  public onCompletion(): void {
    const ct = BreadcrumbStep;
    this.currentStep = this.completed ? ct.Done : ct.CompleteRequest;
  }

  public unmounted(): void {
    clearInterval(this.intref);
  }

  public async mounted(): Promise<void> {
    if (this.history.docNumber === '') {
      this.done();
      return;
    }

    const { id } = this.$route.query;
    this.scrollTo(0);

    // Date Update
    this.intref = setInterval(() => {
      this.now = moment.now();
    }, 1000 * 15);

    // From history
    if (id) {
      const item = this.history.requests.find((x) => x.id === id);
      if (item === undefined) {
        this.showMessage(this.$t('finalStep.notFound'), () => this.done());
        return;
      }

      this.pcn = item;
      this.completed = true;
      this.fromHistory = true;
      return;
    }

    // Process from previous page.
    if (!this.isFormFilled()) {
      this.done();
      return;
    }

    const token = await this.processCaptcha();
    if (!token) {
      const emsg = this.$t('finalStep.captchaError').toString();
      this.showError(emsg, '', () => {
        this.goBack();
      });
      return;
    }

    const { data } = this.store;
    const res = await this.srv.generatePreCreditNote(
      data.docType,
      data.doc,
      data.type,
      data.transaction,
      data.items,
      data.customer,
      token,
      this.store,
    );

    if (!res.isValid) {
      const msg = this.$tfn.getErrorMsg(this, 'finalStep', res.err);
      this.showError(msg, res.err.id, () => {
        this.goBack();
      });
      return;
    }

    this.completed = true;
    this.pcn = this.store.result;
  }

  public processCancelError(): void {
    const err = this.store.error;
    const msg = this.$tfn.getErrorMsg(this, 'cancelRequest', err);
    this.showError(msg, err.id, () => { });
  }

  private goBack(): void {
    this.$router.push(this.localizeRoute('history'));
  }

  private cancelRequest(): void {
    const ComponentClass = Vue.extend(ConfirmModal);
    const instance = new ComponentClass({
      propsData: {
        title: this.$t('cancelRequest.title'),
        message: this.$t('cancelRequest.disclaimer'),
        yes: this.$t('cancelRequest.confirm'),
        no: this.$t('cancelRequest.backOut'),
        ok: this.$t('global.ok'),
        completeMessage: this.$t('cancelRequest.allDone'),
        onError: this.processCancelError,
      },
    });
    const modal = instance as ConfirmModal;
    modal.$mount();

    const parent = window.document.getElementById('dialog');
    if (parent) parent.appendChild(instance.$el);

    modal.show(async (accept: boolean) => {
      if (!accept) return;
      const res = await this.srv.cancelPreCreditNote(
        this.history.docType,
        this.history.docNumber,
        this.pcn,
        this.store,
      );
      await this.srv.listRequestHistory(this.history);
      if (!res.isValid) {
        throw new Error(res.err.msg ? res.err.msg.toString() : '');
      }
    });
  }

  private print(): void {
    if (!this.pcn.pdfUrl) {
      this.showError(this.$t('finalStep.pdfError').toString(), '', () => { });
      return;
    }

    const url = process.env.VUE_APP_API_URL + this.pcn.pdfUrl;
    // eslint-disable-next-line
    if (!(window as any).printJS) return;
    // eslint-disable-next-line
    (window as any).printJS({
      printable: url,
      type: 'pdf',
      onLoadingStart: () => {
        this.preparingPdf = true;
      },
      onLoadingEnd: () => {
        this.preparingPdf = false;
      },
    });
  }

  private share(): void {
    if (!this.pcn.pdfUrl) {
      this.showError(this.$t('finalStep.pdfError').toString(), '', () => { });
      return;
    }

    const url = process.env.VUE_APP_API_URL + this.pcn.pdfUrl;
    const shareOptions = {
      title: `Solicitud #${this.pcn.shortCode}`,
      text: '',
      url,
    };

    if (!navigator.share) return;
    navigator.share(shareOptions);
  }
}
