import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RequestType from '@/core/RequestType';
import History from '@/store/modules/History';
import PreCreditNote from '@/store/modules/PreCreditNote';

@Component
export default class PreCreditNoteHandler extends Vue {
  get history(): History {
    return getModule(History, this.$store);
  }

  get store(): PreCreditNote {
    return getModule(PreCreditNote, this.$store);
  }

  public isTypeSelected(): boolean {
    return this.store.data.type !== RequestType.NOT_SELECTED;
  }

  public isVoucherFilled(): boolean {
    return this.store.data.voucher.trx.trim() !== '';
  }

  public isTransactionReady(): boolean {
    return this.store.data.transaction.trx.trim() !== '';
  }

  public isFormFilled(): boolean {
    return this.store.data.customer.name.trim() !== '';
  }

  public fixVerb(str: TranslateResult | string): string {
    let verb = this.$t('global.refund').toString();
    if (this.store.data.type === RequestType.SWAP) {
      verb = this.$t('global.swap').toString();
    }

    return str.toString().replace('%d', verb);
  }

  public get requestVerb(): string {
    if (this.store.data.type === RequestType.SWAP) {
      return this.$t('global.swap').toString();
    }

    return this.$t('global.refund').toString();
  }
}
