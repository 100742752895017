


















































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Item } from 'client-nc-sdk';
import DomHelper from '@/mixins/DomHelper';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import Localizer from '@/mixins/Localizer';
import Breadcrumb, { BreadcrumbStep } from '@/components/Breadcrumb.vue';
import Spinner from '@/components/elements/Spinner.vue';
import QuantityModal from '@/components/elements/QuantityModal.vue';
import DirectInput from '@/components/elements/DirectInput.vue';
import SubmitBtn from '@/components/elements/SubmitBtn.vue';
import LinkItem from '@/components/elements/LinkItem.vue';
import RequestForm from '@/components/RequestForm.vue';
import TransactionDetails from '@/components/TransactionDetails.vue';
import ProductSelection from '@/components/ProductSelection.vue';
import { CustomerInfo } from '@/core/CustomerInfo';
import Notifier from '@/mixins/Notifier';
import { DefaultVoucherInput } from '@/core/VoucherInput';
import RequestType from '@/core/RequestType';
import { PreTransaction } from '@/store/modules/PreCreditNote';

@Component({
  components: {
    Breadcrumb,
    Spinner,
    QuantityModal,
    DirectInput,
    RequestForm,
    TransactionDetails,
    SubmitBtn,
    LinkItem,
    ProductSelection,
  },
})
export default class Review extends mixins(
  PreCreditNoteHandler,
  Notifier,
  DomHelper,
  Localizer,
) {
  public currentStep: BreadcrumbStep = BreadcrumbStep.CompleteRequest;

  public selected: Array<{ item: Item; quantity: number }> = [];

  public get customer(): CustomerInfo {
    return this.store.data.customer;
  }

  public validCustomer = false;

  public get validPrds(): boolean {
    return this.selected.length > 0;
  }

  public get trx(): PreTransaction {
    return this.store.data.transaction;
  }

  public get type(): RequestType {
    return this.store.data.type;
  }

  public mounted(): void {
    if (!this.isTransactionReady()) this.goBack();
    this.selected = this.store.data.items;
    this.scrollTo(0);
  }

  private goBack(): void {
    this.store.setVoucherInput(DefaultVoucherInput());
    const noItems = (this.store.data.transaction.items || []).length <= 0;
    if (this.store.data.type === RequestType.FULL_REFUND || noItems) {
      this.$router.push(this.localizeRoute('request-full-refund'));
    } else {
      this.$router.push(this.localizeRoute('request-partial-refund'));
    }
  }

  private submit(): void {
    this.$router.push(this.localizeRoute('complete-request'));
  }
}
