












































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DomHelper from '@/mixins/DomHelper';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import Breadcrumb, { BreadcrumbStep } from '@/components/Breadcrumb.vue';
import VoucherFormPe from '@/components/VoucherFormPe.vue';
import VoucherFormCl from '@/components/VoucherFormCl.vue';
import VoucherFormGenerics from '@/mixins/VoucherFormGenerics';
import { DefaultVoucherInput, VoucherInput } from '@/core/VoucherInput';
import Localizer from '@/mixins/Localizer';

@Component({
  components: {
    Breadcrumb,
    VoucherFormPe,
    VoucherFormCl,
  },
})
export default class VoucherLookUp extends mixins(
  PreCreditNoteHandler,
  DomHelper,
  Localizer,
) {
  public currentStep: BreadcrumbStep = BreadcrumbStep.VoucherLookup;

  public data: VoucherInput = DefaultVoucherInput();

  public filled = false;

  public mounted(): void {
    this.checkLocale();
    if (!this.isTypeSelected()) {
      this.goBack();
      return;
    }

    this.store.resetTransaction();
    this.data = this.store.data.voucher;
    const left = JSON.stringify(this.data);
    const right = JSON.stringify(DefaultVoucherInput());
    if (left !== right) setTimeout(() => this.validateAll(), 500);
  }

  public enableQuery(): void {
    this.filled = true;
  }

  public disableQuery(): void {
    this.filled = false;
  }

  private queryTransaction(): void {
    const isValid = this.validateAll();
    if (isValid) {
      this.store.setVoucherInput(this.data);
      this.$router.push(this.localizeRoute('voucher-request'));
    }
  }

  private validateAll(): boolean {
    if (!this.$refs.form) return false;
    const form = this.$refs.form as VoucherFormGenerics;
    if (!form || !form.validateAll) return false;
    return form.validateAll();
  }

  private goBack(): void {
    this.store.setVoucherInput(DefaultVoucherInput());
    this.$router.push(this.localizeRoute('./'));
  }
}
