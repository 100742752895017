import { PreCreditNote as PCN } from 'client-nc-sdk';

const obj = (): PCN => ({
  id: '',
  status: '',
  barcode: '',
  shortCode: '',
  creditNoteType: '',
  total: 0,
  trxReference: '',
  expires: '',
  message: '',
  pdfUrl: '',
  created: '',
  imgUrl: '',
});

export default obj;
