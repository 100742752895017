export interface VoucherInput {
  local: string;
  datetime: string;
  trx: string;
  pos: string;
  amount: string;
}

export const DefaultVoucherInput = (): VoucherInput => ({
  datetime: '',
  local: '',
  pos: '',
  trx: '',
  amount: '',
});
