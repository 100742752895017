
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SubmitBtn extends Vue {
  @Prop() private text!: string;

  @Prop({ default: true }) private on!: boolean;
}
