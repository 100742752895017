var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-selection"},[_c('div',{staticClass:"columns header is-mobile is-variable is-1"},[(!_vm.readonly)?_c('div',{staticClass:"column is-one-fifth"},[_vm._v("Incluir")]):_vm._e(),_c('div',{staticClass:"column"},[_vm._v("Descripción")]),_c('div',{staticClass:"column is-one-quarter"},[_vm._v("Valor")])]),_vm._l((_vm.allItems),function(item){return _c('div',{key:item.uid,class:{
      columns: true,
      'data-item': true,
      'is-mobile': true,
      'is-variable': true,
      'is-1': true,
      selected: !_vm.readonly && _vm.isSelected(item),
    },attrs:{"id":("item" + (item.uid))},on:{"click":function($event){return _vm.selectItem(item)}}},[(!_vm.readonly)?_c('div',{staticClass:"column is-one-fifth selectionable"},[_c('i',{class:{
          far: true,
          'fa-square': !_vm.isSelected(item),
          'fa-check-square': _vm.isSelected(item),
        }})]):_vm._e(),_c('div',{staticClass:"column",style:({ 'padding-left': _vm.readonly ? '25px' : '0' })},[_c('div',{staticClass:"desc"},[_c('b',[_vm._v(_vm._s(_vm.fixNumber(item.quantity))+" x")]),_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"ean"},[_vm._v(_vm._s(item.id))])]),_c('div',{staticClass:"column price is-one-quarter",style:({ 'padding-right': '25px' })},[_vm._v(" "+_vm._s(_vm.amountWithCurrency(item.amount))+" ")])])}),(!_vm.hideTotals)?_c('div',{staticClass:"columns totales is-mobile is-variable is-1"},[_c('div',{staticClass:"column",style:({ 'padding-left': '25px' })},[_c('b',[_vm._v(" "+_vm._s(_vm.fixVerb(_vm.$t('voucherInfo.toRefundOrSwap')))+_vm._s(_vm.value.length > 0 ? (" (" + (_vm.value.length) + ")") : '')+": ")])]),_c('div',{staticClass:"column amount is-one-quarter",style:({ 'padding-right': '25px' })},[_vm._v(" "+_vm._s(_vm.amountWithCurrency(_vm.totalSelected))+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }