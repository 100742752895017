import { AxiosError } from 'axios';
import { ErrorType } from './ErrorType';

export interface ResultError {
  id: string,
  type: ErrorType;
  msg: string;
}

export class ResultWrapper<T> {
  private id = '';

  public isValid = false;

  public get txid(): string {
    return this.id;
  }

  public set txid(val: string) {
    this.id = val;
    this.err.id = val;
  }

  public result: T | undefined = undefined;

  public err: ResultError = {
    id: '',
    type: ErrorType.NoError,
    msg: '',
  };

  public constructor(obj: T | undefined, error: AxiosError | Error | string | undefined = undefined, defaultCode = -1) {
    this.result = obj;
    if (obj) this.isValid = true;

    if (!error) return;
    if (typeof error === 'string' || error instanceof String) {
      this.err.type = defaultCode;
      this.err.msg = error.toString();
      return;
    }

    const aerr = error as AxiosError;
    if (aerr.response) {
      this.err.type = aerr.response.status;

      const body = aerr.response.data;
      if (typeof body === 'string' || body instanceof String) {
        this.err.msg = body ? body.toString() : '';
      } else if (body.message) {
        this.err.msg = body.message;
      } else {
        this.err.msg = error.message;
      }

      return;
    }

    this.err.type = defaultCode;
    this.err.msg = error.message ? error.message : error.toString();
  }
}
