



































































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Item } from 'client-nc-sdk';
import DomHelper from '@/mixins/DomHelper';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import Localizer from '@/mixins/Localizer';
import Breadcrumb, { BreadcrumbStep } from '@/components/Breadcrumb.vue';
import Spinner from '@/components/elements/Spinner.vue';
import QuantityModal from '@/components/elements/QuantityModal.vue';
import DirectInput from '@/components/elements/DirectInput.vue';
import SubmitBtn from '@/components/elements/SubmitBtn.vue';
import LinkItem from '@/components/elements/LinkItem.vue';
import RequestForm from '@/components/RequestForm.vue';
import TransactionDetails from '@/components/TransactionDetails.vue';
import ProductSelection from '@/components/ProductSelection.vue';
import sleep from '@/core/helpers/Sleep';
import { CustomerInfo, DefaultCustomerInfo } from '@/core/CustomerInfo';
import Notifier from '@/mixins/Notifier';
import { DefaultVoucherInput } from '@/core/VoucherInput';
import RequestType from '@/core/RequestType';
import { PreTransaction } from '@/store/modules/PreCreditNote';

enum RequestPhase {
  VERIFY_INFO = 1,
  SELECT_PRODUCTS = 2,
  FILL_FORM = 3,
}

@Component({
  components: {
    Breadcrumb,
    Spinner,
    QuantityModal,
    DirectInput,
    RequestForm,
    TransactionDetails,
    SubmitBtn,
    LinkItem,
    ProductSelection,
  },
})
export default class PartialRefundSwap extends mixins(
  PreCreditNoteHandler,
  Notifier,
  DomHelper,
  Localizer,
) {
  private policyAccepted = false;

  public currentStep: BreadcrumbStep = BreadcrumbStep.VoucherLookup;

  public selected: Array<{ item: Item; quantity: number }> = [];

  public phase: RequestPhase = RequestPhase.VERIFY_INFO;

  public customer: CustomerInfo = DefaultCustomerInfo();

  public validCustomer = false;

  public get validPrds(): boolean {
    return this.selected.length > 0;
  }

  public get trx(): PreTransaction {
    return this.store.data.transaction;
  }

  public get type(): RequestType {
    return this.store.data.type;
  }

  public mounted(): void {
    if (!this.isTransactionReady()) {
      this.goBack();
      return;
    }

    this.customer = {
      name: this.history.autoComplete.name || '',
      address: this.history.autoComplete.firstLine || '',
      city: this.history.autoComplete.secondLine || '',
      phone: this.history.autoComplete.phone || '',
      email: this.history.autoComplete.email || '',
      sendTerms: false,
    };
  }

  @Watch('phase')
  public onPhaseChange(): void {
    if (this.phase === RequestPhase.VERIFY_INFO) {
      this.currentStep = BreadcrumbStep.VoucherLookup;
    } else if (this.phase === RequestPhase.SELECT_PRODUCTS) {
      this.currentStep = BreadcrumbStep.ItemSelection;
    } else if (this.phase === RequestPhase.FILL_FORM) {
      this.currentStep = BreadcrumbStep.FillForm;
    }
  }

  private async backToProducts() {
    this.scrollTo(this.$refs.phase2);
    await sleep(500);
    this.phase = RequestPhase.SELECT_PRODUCTS;
  }

  private async backToInfo() {
    this.scrollTo(0);
    await sleep(500);
    this.phase = RequestPhase.VERIFY_INFO;
  }

  private goBack(): void {
    this.store.setVoucherInput(DefaultVoucherInput());
    this.$router.push(this.localizeRoute('voucher-lookup'));
  }

  private async gotoPrds(): Promise<void> {
    this.phase = RequestPhase.SELECT_PRODUCTS;
    await sleep(100);
    this.scrollTo(this.$refs.phase2);
  }

  private async gotoForm(): Promise<void> {
    if (!this.validPrds) {
      this.showMessage(this.$t('voucherInfo.emptyProds'));
      return;
    }

    this.phase = RequestPhase.FILL_FORM;
    await sleep(100);
    this.scrollTo(this.$refs.phase3);
  }

  private submit(): void {
    if (!this.validPrds) {
      this.showMessage(this.$t('voucherInfo.emptyProds'), () => {
        this.scrollTo(this.$refs.phase2);
      });
      return;
    }

    if (!this.validCustomer) {
      this.showMessage(this.$t('voucherInfo.incompleteForm'));
      return;
    }

    if (!this.policyAccepted) {
      this.showMessage(this.$t('voucherInfo.mustAcceptPolicy'));
      return;
    }

    this.store.setItems(this.selected);
    this.store.setCustomerData(this.customer);
    this.$router.push(this.localizeRoute('review'));
  }
}
