































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BreadcrumbItem from '@/components/elements/BreadcrumbItem.vue';
import Localizer from '@/mixins/Localizer';

export enum BreadcrumbStep {
  VoucherLookup = 1,
  ItemSelection = 2,
  FillForm = 3,
  CompleteRequest = 4,
  Done = 5,
}

@Component({
  components: {
    BreadcrumbItem,
  },
})
export default class Breadcrumb extends mixins(Localizer) {
  @Prop({ default: BreadcrumbStep.VoucherLookup }) private step!: number;

  @Prop({ default: false }) private stickToTop!: boolean;

  @Prop({ default: () => [] }) private hideSteps!: Array<number>;

  @Prop({ default: './' }) private backLink!: string;

  public get voucherLookupVisible(): boolean {
    return this.isStepVisible(BreadcrumbStep.VoucherLookup);
  }

  public get itemSelectionVisible(): boolean {
    return this.isStepVisible(BreadcrumbStep.ItemSelection);
  }

  public get fillFormVisible(): boolean {
    return this.isStepVisible(BreadcrumbStep.FillForm);
  }

  public get completeRequestVisible(): boolean {
    return this.isStepVisible(BreadcrumbStep.CompleteRequest);
  }

  public isStepVisible(n: number): boolean {
    return !this.hideSteps.includes(n);
  }

  private goBack(): void {
    this.$router.push(this.localizeRoute(this.backLink));
  }
}
