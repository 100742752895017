export interface ILocaleFn {
  validateDoc(): boolean;
}

export enum Locales {
  ES_PE = 'es-PE',
  ES_CL = 'es-CL',
}

export const LOCALES = [
  { value: Locales.ES_PE, caption: 'Español (Perú)' },
  { value: Locales.ES_CL, caption: 'Español (Chile)' },
];
