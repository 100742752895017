import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';
import AlertModal from '@/components/elements/AlertModal.vue';

@Component({
  components: {
    AlertModal,
  },
})
export default class Notifier extends Vue {
  public showError(err: Error | string | undefined, ref: string, after: () => void): void {
    const defaultErr = err ? err.toString() : '';
    const msg = err instanceof Error ? err.message : defaultErr;
    this.showMessage(msg || this.$t('global.unknownError'), after, ref);
  }

  public showMessage(
    msg: TranslateResult | string,
    after: () => void = () => { },
    ref: string | undefined = undefined,
  ): void {
    const ComponentClass = Vue.extend(AlertModal);
    const instance = new ComponentClass({
      propsData: {
        ok: this.$t('global.ok').toString(),
      },
    });
    const modal = instance as AlertModal;
    modal.$mount();

    const parent = window.document.getElementById('dialog');
    if (parent) parent.appendChild(instance.$el);

    modal.show(msg.toString(), async () => {
      after();
      modal.remove();
    }, ref);
  }
}
