import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import VoucherLookUp from '../views/VoucherLookUp.vue';
import VoucherRequest from '../views/VoucherRequest.vue';
import FullRefund from '../views/requests/FullRefund.vue';
import PartialRefundSwap from '../views/requests/PartialRefundSwap.vue';
import CompleteRequest from '../views/CompleteRequest.vue';
import History from '../views/History.vue';
import Review from '../views/Review.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/:country',
    name: 'Home',
    component: Home,
  },
  {
    path: '/',
    redirect: { path: '/cl' },
  },
  {
    path: '/:country/voucher-lookup',
    name: 'Voucher Lookup',
    component: VoucherLookUp,
  },
  {
    path: '/:country/voucher-request',
    name: 'Voucher Request',
    component: VoucherRequest,
  },
  {
    path: '/:country/request-full-refund',
    name: 'Full Refund',
    component: FullRefund,
  },
  {
    path: '/:country/request-partial-refund',
    name: 'Partial Refund',
    component: PartialRefundSwap,
  },
  {
    path: '/:country/complete-request',
    name: 'Complete request',
    component: CompleteRequest,
  },
  {
    path: '/:country/history',
    name: 'Request History',
    component: History,
  },
  {
    path: '/:country/review',
    name: 'Review',
    component: Review,
  },
  {
    path: '/:country/details',
    name: 'Details',
    component: CompleteRequest,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
