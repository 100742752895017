
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BreadcrumbItem extends Vue {
  @Prop() private title!: string;

  @Prop() private isCurrent!: boolean;

  @Prop() private completed!: boolean;
}
