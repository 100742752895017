


































































import Component, { mixins } from 'vue-class-component';
import VoucherFormGenerics from '@/mixins/VoucherFormGenerics';

@Component
export default class VoucherFormPe extends mixins(VoucherFormGenerics) {
  public showExample = false;

  public toggleExample(): void {
    this.showExample = !this.showExample;
  }
}
