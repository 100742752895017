import Vue from 'vue';
import Vuex from 'vuex';
import History from './modules/History';
import PreCreditNote from './modules/PreCreditNote';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    preCreditNote: PreCreditNote,
    history: History,
  },
});
