import { PreCreditNote, Transaction, GeneralApi, Configuration, PreCreditNotesApi, ListPreCreditNotes, PreCreditNoteRequest, Item } from 'client-nc-sdk';
import { v4 as uuidv4 } from 'uuid';
import { Locales } from '@/i18n/locales';
import { CustomerInfo } from '../CustomerInfo';
import { ItemWithQuantity } from '../ItemWithQuantity';
import RequestType from '../RequestType';
import { VoucherInput } from '../VoucherInput';
import { CancelPCNObs, HistoryObs, IPCNProvider, ParamMap, ParamsObs, PreCreditNoteObs, TransactionObs } from './lib/ProviderInterfaces';
import { ResultWrapper } from './lib/ResultWrapper';
import RequestStatus from '../RequestStatus';
import { PreTransaction } from '@/store/modules/PreCreditNote';

const COMMERCE = 'Tottus';
const PLATFORM = 'WEB';
const PRODUCT = 'AUTOSAC';
const CONSUMER_REF = 'AUTOSAC_WEBAPP';

export class TottusProvider implements IPCNProvider {
  private parameters: ParamMap | undefined = undefined;

  private conf(): Configuration {
    return new Configuration({
      basePath: process.env.VUE_APP_API_URL,
    });
  }

  private creatUserTrxId(): string {
    return uuidv4();
  }

  private getCountry(locale: Locales) {
    switch (locale) {
      case Locales.ES_CL: return 'CL';
      case Locales.ES_PE: return 'PE';
      default: return 'PE';
    }
  }

  private formOwner(preffix: string, doc: string): string {
    return `${preffix}:${doc}`;
  }

  private getShortDate(strDate: string, yearFirst = false): string | undefined {
    let dateParts = strDate.substring(0, 10).split('-');
    if (dateParts.length <= 1) {
      dateParts = strDate.substring(0, 10).split('/');
    }

    const day = Number.parseInt(dateParts[yearFirst ? 2 : 0], 10);
    const dd = day.toString().padStart(2, '0');
    const month = Number.parseInt(dateParts[1], 10);
    const mm = month.toString().padStart(2, '0');
    const year = Number.parseInt(dateParts[yearFirst ? 0 : 2], 10);
    const jdate = `${year}-${mm}-${dd}`;

    // Validate Date
    let validDate = true;
    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      validDate = false;
    }

    // Adjust for leap years
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    if (day <= 0 || day > monthLength[month - 1]) {
      validDate = false;
    }

    return validDate ? jdate : undefined;
  }

  public async getTransaction(
    input: VoucherInput,
    captcha: string,
    obs: TransactionObs,
  ): Promise<ResultWrapper<Transaction>> {
    const api = new PreCreditNotesApi(this.conf());
    obs.setProcessing(true);
    const txId = this.creatUserTrxId();

    try {
      const jdate = this.getShortDate(input.datetime);
      if (!jdate) {
        const w = new ResultWrapper<Transaction>(undefined, 'Date Validation Error', -10);
        w.txid = txId;
        obs.setError(w.err);
        return w;
      }

      const apires = await api.getTransaction(
        this.getCountry(obs.locale),
        COMMERCE,
        captcha,
        parseInt(input.local, 10),
        parseInt(input.pos, 10),
        jdate,
        parseInt(input.trx, 10),
        parseFloat(input.amount),
        undefined,
        CONSUMER_REF,
        undefined,
        txId,
        PLATFORM,
        PRODUCT,
      );

      const result = new ResultWrapper(apires.data);
      result.txid = txId;
      if (!result.result) throw new Error('Unknown Error');
      obs.setTransaction(result.result);
      return result;
    } catch (e) {
      const w = new ResultWrapper<Transaction>(undefined, e);
      w.txid = txId;
      obs.setError(w.err);
      return w;
    } finally {
      obs.setProcessing(false);
    }
  }

  public async cancelPreCreditNote(
    docType: string,
    doc: string,
    pcn: PreCreditNote,
    obs: CancelPCNObs,
  ): Promise<ResultWrapper<PreCreditNote>> {
    const api = new PreCreditNotesApi(this.conf());
    const txId = this.creatUserTrxId();

    obs.setProcessing(true);
    try {
      const apires = await api.cancelPreCreditNote(
        this.getCountry(obs.locale),
        COMMERCE,
        this.formOwner(docType, doc),
        pcn.id,
        undefined,
        CONSUMER_REF,
        undefined,
        txId,
        PLATFORM,
        PRODUCT,
      );

      if (apires.data.errors) {
        const w = new ResultWrapper<PreCreditNote>(undefined, 'Unknown error while canceling.', -1);
        w.txid = txId;
        obs.setError(w.err);
        return w;
      }

      // eslint-disable-next-line
      pcn.status = RequestStatus.CANCELED;
      const result = new ResultWrapper(pcn);
      result.txid = txId;
      obs.setCanceledPreCreditNote(pcn);
      return result;
    } catch (e) {
      const w = new ResultWrapper<PreCreditNote>(undefined, e);
      w.txid = txId;
      obs.setError(w.err);
      return w;
    } finally {
      obs.setProcessing(false);
    }
  }

  public lastParameters(): ParamMap | undefined {
    return this.parameters;
  }

  public async listParameters(obs: ParamsObs): Promise<ResultWrapper<ParamMap>> {
    if (this.parameters) return new ResultWrapper(this.parameters);

    const api = new GeneralApi(this.conf());
    const txId = this.creatUserTrxId();
    try {
      const res = await api.getParameters(
        this.getCountry(obs.locale),
        COMMERCE,
        undefined,
        CONSUMER_REF,
        undefined,
        txId,
        PLATFORM,
        PRODUCT,
      );
      const d: ParamMap = {};
      res.data.forEach((x) => { d[x.key] = x.value; });
      this.parameters = d;
      const result = new ResultWrapper<ParamMap>(d);
      result.txid = txId;
      return result;
    } catch (e) {
      const errRes = new ResultWrapper<ParamMap>(undefined, e);
      errRes.txid = txId;
      return errRes;
    }
  }

  public async listRequestHistory(obs: HistoryObs): Promise<ResultWrapper<ListPreCreditNotes>> {
    const api = new PreCreditNotesApi(this.conf());
    const txId = this.creatUserTrxId();

    obs.setProcessing(true);
    try {
      const result = await api.listPreCreditNotes(
        this.getCountry(obs.locale),
        COMMERCE,
        this.formOwner(obs.docType, obs.docNumber),
        CONSUMER_REF,
        txId,
        undefined,
        txId,
        PLATFORM,
        PRODUCT,
      );
      const history = new ResultWrapper(result.data);
      if (!history.result) throw new Error('Unknown Error');
      history.txid = txId;
      obs.setHistory(history.result.list ? history.result.list : []);
      obs.setAutocomplete(history.result.autoComplete ? history.result.autoComplete : {});
      return history;
    } catch (e) {
      const w = new ResultWrapper<ListPreCreditNotes>(undefined, e);
      w.txid = txId;
      obs.setError(w.err);
      return w;
    } finally {
      obs.setProcessing(false);
    }
  }

  public async generatePreCreditNote(
    docType: string,
    doc: string,
    type: RequestType,
    trx: PreTransaction,
    items: ItemWithQuantity[],
    c: CustomerInfo,
    captcha: string,
    obs: PreCreditNoteObs,
  ): Promise<ResultWrapper<PreCreditNote>> {
    const api = new PreCreditNotesApi(this.conf());
    const txId = this.creatUserTrxId();

    obs.setProcessing(true);
    try {
      const jdate = this.getShortDate(trx.trxDate, true);
      if (!jdate) {
        const w = new ResultWrapper<PreCreditNote>(undefined, 'Date Validation Error', -10);
        w.txid = txId;
        obs.setError(w.err);
        return w;
      }

      const req: PreCreditNoteRequest = {
        creditNoteType: type,
        customer: {
          documentNumber: doc,
          documentType: docType,
          name: c.name,
          addressDetails: {
            firstLine: c.address.trim() === '' ? undefined : c.address,
            secondLine: c.city.trim() === '' ? undefined : c.city,
            geocode: undefined,
          },
          email: c.email,
          phone: c.phone,
        },
        items: items.map((x) => {
          const replica = JSON.parse(JSON.stringify(x.item)) as Item;
          replica.quantity = x.quantity;
          if (!replica.description || replica.description.trim() === '') {
            replica.description = 'UNKNOWN';
          }

          if (!replica.unit || replica.unit.trim() === '') {
            replica.unit = 'UNI';
          }

          if (!replica.id || replica.id.trim() === '') {
            replica.id = 'NOID';
          }

          return replica;
        }),
        message: '',
        total: 0,
        sendTerms: c.sendTerms,
      };

      const apires = await api.createPreCreditNote(
        this.getCountry(obs.locale),
        COMMERCE,
        captcha,
        this.formOwner(docType, doc),
        parseInt(trx.store, 10),
        parseInt(trx.pos, 10),
        jdate,
        parseInt(trx.trx, 10),
        req,
        undefined,
        CONSUMER_REF,
        undefined,
        txId,
        PLATFORM,
        PRODUCT,
      );

      const result = new ResultWrapper(apires.data);
      result.txid = txId;
      if (!result.result) throw new Error('Unknown Error');
      obs.setPreCreditNote(result.result);
      return result;
    } catch (e) {
      const w = new ResultWrapper<PreCreditNote>(undefined, e);
      w.txid = txId;
      obs.setError(w.err);
      return w;
    } finally {
      obs.setProcessing(false);
    }
  }
}
