












































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { PreCreditNote } from 'client-nc-sdk';
import Breadcrumb, { BreadcrumbStep } from '@/components/Breadcrumb.vue';
import RequestStatus from '@/core/RequestStatus';
import PreCreditNoteHandler from '@/mixins/PreCreditNoteHandler';
import SubmitBtn from '@/components/elements/SubmitBtn.vue';
import RTypeButton from '@/components/elements/RTypeButton.vue';
import LinkItem from '@/components/elements/LinkItem.vue';
import Localizer from '@/mixins/Localizer';
import TransactionDetails from '@/components/TransactionDetails.vue';
import { DefaultProvider } from '@/core/provider';
import DomHelper from '@/mixins/DomHelper';
import RequestType from '@/core/RequestType';
import Notifier from '@/mixins/Notifier';

@Component({
  components: {
    Breadcrumb,
    RTypeButton,
    SubmitBtn,
    LinkItem,
  },
})
export default class History extends mixins(
  PreCreditNoteHandler,
  Localizer,
  Notifier,
  TransactionDetails,
  DomHelper,
) {
  private srv = DefaultProvider;

  public canSelect = false;

  public currentStep: BreadcrumbStep = BreadcrumbStep.VoucherLookup;

  public validTypes = RequestType;

  public get requests(): Array<PreCreditNote> {
    return this.history.requests;
  }

  public selectType(): void {
    if (this.history.activeRequests.length >= this.history.maxRequestsAllowed) {
      const msg = this.$tfn.getErrorMsg(this, 'finalStep', {
        id: '',
        msg: '',
        type: 403,
      });
      this.showError(msg, '', () => {});
      return;
    }

    this.canSelect = true;
    setTimeout(() => {
      this.scrollTo(this.$refs.tsel);
    }, 100);
  }

  public selectRequestType(rtype: RequestType): void {
    this.store.setRequestType(rtype);
    this.$router.push(this.localizeRoute('voucher-lookup'));
  }

  public mounted(): void {
    if (this.history.docNumber === '') {
      this.goBack();
      return;
    }

    this.srv.listRequestHistory(this.history).finally();
  }

  private isRequestCompleted(item: PreCreditNote): boolean {
    return (
      item.status.toUpperCase() === RequestStatus.COMPLETED ||
      item.status.toUpperCase() === RequestStatus.PROCESSED
    );
  }

  private isRequestPending(item: PreCreditNote): boolean {
    return item.status.toUpperCase() === RequestStatus.PENDING;
  }

  private isRequestCanceled(item: PreCreditNote): boolean {
    return (
      item.status.toUpperCase() === RequestStatus.CANCELED ||
      item.status.toUpperCase() === RequestStatus.EXPIRED
    );
  }

  private statusName(item: PreCreditNote): string {
    return this.$t(`global.status${item.status.toUpperCase()}`).toString();
  }

  private goBack(): void {
    this.$router.push(this.localizeRoute('./'));
  }

  private seeDetails(item: PreCreditNote): void {
    this.$router.push(this.localizeRoute(`details?id=${item.id}`));
  }
}
