









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RTypeButton extends Vue {
  @Prop() private title!: string;

  @Prop() private src!: string;

  public onClick(): void {
    this.$emit('click');
  }
}
