var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'breadcrumb-item': true,
    column: true,
    'is-current': _vm.isCurrent,
    completed: _vm.completed,
  }},[_c('div',{staticClass:"bar-left"}),_c('div',{staticClass:"bar-right"}),_c('div',{staticClass:"circle-wrapper"},[_c('i',{class:{
        far: _vm.isCurrent && !_vm.completed,
        fas: _vm.completed || !_vm.isCurrent,
        'fa-circle': true,
      }})]),_c('div',{class:{ txt: true, 'txt-active': _vm.completed }},[_vm._v(_vm._s(_vm.title))])])}
var staticRenderFns = []

export { render, staticRenderFns }